import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';

@Component({
  selector: '[togglable]',
  template: '<ng-content *ngIf="visible === true"></ng-content>',
  exportAs: 'togglable',
  standalone: true,
  imports: [
    CommonModule
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TogglableComponent {

  /****************************************************************** VARIABLES ******************************************************************/

  public get visible(): boolean { return this._visible }

  private _visible = false;

  /****************************************************************** LIFE CYCLE ******************************************************************/

  constructor(private _cdr: ChangeDetectorRef) { }

  /****************************************************************** PUBLIC ******************************************************************/
  /**
   * 
   * @param forcedValue
   */
  public toggle(forcedValue: boolean | null = null) {
    this._visible = forcedValue !== null ? forcedValue : !this._visible;

    this._cdr.markForCheck();
  }

}
