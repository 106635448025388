import { Translator } from '@sfpd/ng-ui/core';
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class TranslatorService implements Translator {

  public translate(key: string, params?: any[]): string {
    switch (key) {
      case 'ng-ui-paginator-ipp': return 'Items per page';
    }

    return '';
  }

}
