import { Injectable } from "@angular/core";
import { ValidationErrors } from '@angular/forms';
import { FormErrorParser } from '@sfpd/ng-ui/form';

@Injectable({
  providedIn: "root"
})
export class FormErrorParserService implements FormErrorParser {

  /** */
  public parse(errors: ValidationErrors): string[] {
    const values: string[] = [];

    if ('required' in errors) {
      values.push("This field is mandatory !");
    }
    else if ('jsonInvalid' in errors) {
      values.push("This field must contain valid JSON !");
    }

    return values;
  }
}