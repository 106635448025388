<h1 class="flex-row flex-aic gap1">
  <ng-ui-icon name="circle-nodes"
              brush="regular"></ng-ui-icon>
  Applications
</h1>

<div class="box mt2 flex-row flex-aic flex-jcc">
  <ngs-container [query$]="query$"
                 [data$]="data$">
    <ng-template let-applications>
      <div ng-ui-button-group
           alignment="end">
        <button ng-ui-button
                color="primary"
                icon="plus"
                (click)="showUpsertForm(null)">
          New application
        </button>
      </div>

      <div class="upsert-form"
           *ngIf="upsertFormVisible">
        <noti-application-upsert-form [id]="current"
                                      (add)="upsertFormVisible = false"
                                      (cancel)="upsertFormVisible = false"></noti-application-upsert-form>
      </div>

      <ng-ui-table class="mt2"
                   [source]="applications"
                   displayTopPagination="false">
        <tr *ngUiTableHeaderRow>
          <th scope="col"
              ngUiTableHeader="name"
              sortable="true"
              sorted="true"
              sortDirection="ascending"
              filterable="true">Name</th>
          <th scope="col"
              ngUiTableHeader="identificationKey"
              sortable="true"
              filterable="true">Identification key</th>
          <th scope="col"
              ngUiTableHeader="retention"
              sortable="true">Retention</th>
          <th scope="col"
              class="icon"></th>
        </tr>
        <tr class="pointer"
            *ngUiTableBodyRow="let application">
          <td (click)="showUpsertForm(application.id)">{{ application.name }}</td>
          <td (click)="showUpsertForm(application.id)">{{ application.identificationKey }}</td>
          <td (click)="showUpsertForm(application.id)">{{ application.retention ? ((application.retention | number) + '
            days') : 'No expiration' }}</td>
          <td>
            <ng-ui-icon name="arrows-rotate"
                        color="error"
                        tooltip="Reset"
                        tooltipColor="error"
                        (click)="showResetDialog(application.id)"></ng-ui-icon>
          </td>
        </tr>
        <tr *ngUiTableBodyEmptyRow>
          <td colspan="4"
              class="text-center">No data</td>
        </tr>
      </ng-ui-table>

      <ng-ui-dialog mode="alert"
                    color="error"
                    icon="arrows-rotate"
                    label="Confirmation">
        <ng-container *ngUiDialogContent>
          <div>Resetting an application will delete all its notifications.</div>
          <div class="mt1">Continue?</div>
        </ng-container>
        <ng-container *ngUiDialogButtons>
          <button ng-ui-button
                  color="error"
                  icon="arrows-rotate"
                  size="medium"
                  [loading]="resetting === true"
                  (click)="reset()">
            Reset
          </button>
          <button ng-ui-button
                  color="primary"
                  icon="times"
                  size="medium"
                  (click)="hideResetDialog()">
            Cancel
          </button>
        </ng-container>
      </ng-ui-dialog>
    </ng-template>
  </ngs-container>
</div>
