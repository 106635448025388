import * as i0 from '@angular/core';
import { ElementRef, inject, NgZone, Directive, Input, NgModule } from '@angular/core';
import { coerceBoolean } from '@sfpd/ng-ui/core';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
import tippy from 'tippy.js';

// TODO: Guidelines
class TooltipDirective {
  constructor() {
    this._element = inject(ElementRef);
    this.zone = inject(NgZone);
    this._allowHTML = false;
    this._animation = 'scale';
    this._arrow = true;
    this._content = null;
    this._delay = 0;
    this._instance = null;
    this._interactive = true;
    this._maxWidth = 'none';
    this._placement = 'auto';
    this._subscription = null;
    this._color = 'primary';
    this._zIndex = 9999;
  }
  set content(value) {
    if (this._instance) {
      if (value !== null && typeof value !== 'undefined' && value !== '') {
        this._instance.setContent(value);
      } else {
        this._instance.destroy();
        this._instance = null;
      }
    }
    this._content = value;
    if (typeof this._content !== 'string' && !this._instance) {
      this._initTooltip(false);
    }
  }
  set allowHTML(value) {
    if (this._instance) {
      this._instance.setProps({
        allowHTML: coerceBoolean(value)
      });
    }
    this._allowHTML = coerceBoolean(value);
  }
  set animation(value) {
    if (this._instance) {
      this._instance.setProps({
        animation: value + '-extreme'
      });
    }
    this._animation = value;
  }
  set arrow(value) {
    if (this._instance) {
      this._instance.setProps({
        arrow: coerceBoolean(value)
      });
    }
    this._arrow = coerceBoolean(value);
  }
  set delay(value) {
    if (this._instance) {
      this._instance.setProps({
        delay: value
      });
    }
    this._delay = value;
  }
  set interactive(value) {
    if (this._instance) {
      this._instance.setProps({
        interactive: coerceBoolean(value)
      });
    }
    this._interactive = coerceBoolean(value);
  }
  set maxWidth(value) {
    if (this._instance) {
      this._instance.setProps({
        maxWidth: value
      });
    }
    this._maxWidth = value;
  }
  set placement(value) {
    if (this._instance) {
      this._instance.setProps({
        placement: value
      });
    }
    this._placement = value;
  }
  set color(value) {
    if (this._instance) {
      this._instance.setProps({
        theme: value.toString()
      });
    }
    this._color = value;
  }
  set zIndex(value) {
    if (this._instance) {
      this._instance.setProps({
        zIndex: value
      });
    }
    this._zIndex = value;
  }
  ngOnInit() {
    this._subscription = fromEvent(this._element.nativeElement, 'mouseenter').pipe(filter(() => this._instance === null)).subscribe(() => this._initTooltip());
  }
  /** */
  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }
  /** */
  _initTooltip(showOnCreate = true) {
    if (this._content !== null && typeof this._content !== 'undefined' && this._content !== '') {
      this.zone.runOutsideAngular(() => {
        this._instance = tippy(this._element.nativeElement, {
          appendTo: document.body,
          allowHTML: this._allowHTML,
          animation: this._animation + '-extreme',
          arrow: this._arrow,
          inertia: true,
          content: this._content,
          delay: this._delay,
          interactive: this._interactive,
          maxWidth: this._maxWidth,
          placement: this._placement,
          showOnCreate: showOnCreate,
          zIndex: this._zIndex,
          theme: this._color.toString()
        });
      });
    }
  }
  static {
    this.ɵfac = function TooltipDirective_Factory(t) {
      return new (t || TooltipDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: TooltipDirective,
      selectors: [["", "tooltip", ""]],
      inputs: {
        content: [0, "tooltip", "content"],
        allowHTML: [0, "tooltipAllowHTML", "allowHTML"],
        animation: [0, "tooltipAnimation", "animation"],
        arrow: [0, "tooltipArrow", "arrow"],
        delay: [0, "tooltipDelay", "delay"],
        interactive: [0, "tooltipInteractive", "interactive"],
        maxWidth: [0, "tooltipMaxWidth", "maxWidth"],
        placement: [0, "tooltipPlacement", "placement"],
        color: [0, "tooltipColor", "color"],
        zIndex: [0, "tooltipZIndex", "zIndex"]
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipDirective, [{
    type: Directive,
    args: [{
      selector: '[tooltip]',
      standalone: true
    }]
  }], null, {
    content: [{
      type: Input,
      args: ['tooltip']
    }],
    allowHTML: [{
      type: Input,
      args: ['tooltipAllowHTML']
    }],
    animation: [{
      type: Input,
      args: ['tooltipAnimation']
    }],
    arrow: [{
      type: Input,
      args: ['tooltipArrow']
    }],
    delay: [{
      type: Input,
      args: ['tooltipDelay']
    }],
    interactive: [{
      type: Input,
      args: ['tooltipInteractive']
    }],
    maxWidth: [{
      type: Input,
      args: ['tooltipMaxWidth']
    }],
    placement: [{
      type: Input,
      args: ['tooltipPlacement']
    }],
    color: [{
      type: Input,
      args: ['tooltipColor']
    }],
    zIndex: [{
      type: Input,
      args: ['tooltipZIndex']
    }]
  });
})();
const DIRECTIVES = [TooltipDirective];
class TooltipModule {
  static {
    this.ɵfac = function TooltipModule_Factory(t) {
      return new (t || TooltipModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: TooltipModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TooltipModule, [{
    type: NgModule,
    args: [{
      imports: DIRECTIVES,
      exports: DIRECTIVES
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, TooltipDirective, TooltipModule };
