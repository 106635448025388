<ng-ui-picker noResultLabel="User not found"
              [items]="users"
              [filterFn]="filter"
              [sorterFn]="sorter"
              [formatterFn]="formatter"
              [(ngModel)]="value"
              (picked)="picked($event)">
    <ng-template let-user>
        {{ user.firstName }} {{ user.lastName | uppercase }}
    </ng-template>
</ng-ui-picker>