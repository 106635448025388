import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Notification } from '@app/models';
import { ApplicationPipe } from '@app/pipes';
import { NotificationService } from '@app/services';
import { AppState } from '@app/store';
import { NgStoreContainerComponent, StoreComponent } from '@sfpd/ng-store';

@Component({
  selector: 'noti-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  standalone: true,
  imports: [
    NgStoreContainerComponent,
    CommonModule,
    ApplicationPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationComponent extends StoreComponent<AppState, Notification | null> {

  /******************************************************** BINDINGS ********************************************************/

  @Input()
  public set id(value: number) {
    this.query$ = this._notificationService.loadNotification(value);
    this.data$ = this._store.selectValueByKey(s => s.notifications, value);
  }

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(private _notificationService: NotificationService) {
    super();
  }

}
