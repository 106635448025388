import * as i0 from '@angular/core';
import { InjectionToken, inject, ChangeDetectorRef, EventEmitter, booleanAttribute, Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, ContentChildren, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControl } from '@sfpd/ng-ui/core';
import { FormControlComponent } from '@sfpd/ng-ui/form';
const _c0 = ["*"];
const NG_UI_CHECKBOX_OPTIONS = new InjectionToken('ng-ui-checkbox-options');
let nextUniqueId = 0;
class CheckboxComponent extends BaseFormControl {
  constructor() {
    super(...arguments);
    /******************************************************** SERVICES ********************************************************/
    this._fc = inject(FormControlComponent, {
      optional: true
    });
    this._group = inject(CheckboxGroupComponent, {
      optional: true
    });
    this._cdr = inject(ChangeDetectorRef);
    this._options = inject(NG_UI_CHECKBOX_OPTIONS, {
      optional: true
    });
    /******************************************************** BINDINGS ********************************************************/
    this.animated = true;
    this.checkedValue = true;
    this.icon = this._options?.icon ?? 'tick';
    this.position = 'left';
    this.uncheckedValue = false;
    this.value = false;
    this.change = new EventEmitter();
    /******************************************************** VARIABLES ********************************************************/
    this._id = `ng-ui-checkbox-label-${++nextUniqueId}`;
  }
  /******************************************************** CONTROL VALUE ACCESSOR ********************************************************/
  /** */
  writeValue(obj) {
    this.value = obj === this.checkedValue || obj === this.uncheckedValue ? obj : this.uncheckedValue;
    this._cdr.markForCheck();
  }
  /******************************************************** INTERNAL ********************************************************/
  /** */
  _switch(event) {
    if (this.disabled === false && !this._group?.disabled) {
      this.value = this.value === this.checkedValue ? this.uncheckedValue : this.checkedValue;
      this._propagateChange();
      this._group?._updateValue();
      event?.preventDefault();
    }
  }
  /** */
  _updateValue(value) {
    this.value = value;
    this._propagateChange();
    this._cdr.markForCheck();
  }
  /******************************************************** PRIVATE ********************************************************/
  /** */
  _propagateChange() {
    this._onChange && this._onChange(this.value);
    this.change.emit(this.value);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCheckboxComponent_BaseFactory;
      return function CheckboxComponent_Factory(t) {
        return (ɵCheckboxComponent_BaseFactory || (ɵCheckboxComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CheckboxComponent)))(t || CheckboxComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CheckboxComponent,
      selectors: [["ng-ui-checkbox"]],
      hostAttrs: ["role", "checkbox"],
      hostVars: 12,
      hostBindings: function CheckboxComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keydown.space", function CheckboxComponent_keydown_space_HostBindingHandler($event) {
            return ctx._switch($event);
          })("keydown.enter", function CheckboxComponent_keydown_enter_HostBindingHandler($event) {
            return ctx._switch($event);
          })("click", function CheckboxComponent_click_HostBindingHandler() {
            return ctx._switch();
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("aria-checked", ctx.value === ctx.checkedValue)("aria-disabled", ctx.disabled === true || (ctx._group == null ? null : ctx._group.disabled) === true ? "true" : "false")("aria-labelledby", !!(ctx._fc == null ? null : ctx._fc.label) ? ctx._fc.id + "-label" : ctx._id)("tabindex", ctx.disabled === true || (ctx._group == null ? null : ctx._group.disabled) === true ? null : 0);
          i0.ɵɵclassProp("ng-ui-checkbox-after", ctx.position === "right")("ng-ui-checkbox-animated", ctx._group ? ctx._group.animated === true : ctx.animated === true)("ng-ui-checkbox-before", ctx.position === "left")("ng-ui-checkbox-disabled", ctx.disabled === true || (ctx._group == null ? null : ctx._group.disabled) === true);
        }
      },
      inputs: {
        animated: [2, "animated", "animated", booleanAttribute],
        checkedValue: "checkedValue",
        icon: "icon",
        position: "position",
        uncheckedValue: "uncheckedValue",
        value: "value"
      },
      outputs: {
        change: "change"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: CheckboxComponent,
        multi: true
      }]), i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 3,
      vars: 9,
      consts: [[1, "far", "fa-fw", "ng-ui-checkbox-icon"], [1, "ng-ui-checkbox-label", 3, "id"]],
      template: function CheckboxComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵelement(0, "i", 0);
          i0.ɵɵelementStart(1, "span", 1);
          i0.ɵɵprojection(2);
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵclassProp("fa-check-square", ctx.value === ctx.checkedValue && ctx.icon === "tick")("fa-solid", ctx.value === ctx.checkedValue)("fa-square", ctx.value !== ctx.checkedValue)("fa-times-square", ctx.value === ctx.checkedValue && ctx.icon === "cross");
          i0.ɵɵadvance();
          i0.ɵɵproperty("id", ctx._id);
        }
      },
      styles: ["ng-ui-checkbox{display:inline-flex;align-items:center;cursor:pointer;transition:color .75s;-webkit-user-select:none;user-select:none;gap:calc(.75rem * var(--ng-ui-gap-factor))}ng-ui-checkbox .ng-ui-checkbox-icon{flex-shrink:0}ng-ui-checkbox.ng-ui-form-normal-validation.ng-touched.ng-invalid,ng-ui-checkbox.ng-ui-form-extended-validation.ng-touched.ng-invalid{color:var(--ng-ui-error-darker)}ng-ui-checkbox.ng-ui-form-extended-validation.ng-touched.ng-valid{color:var(--ng-ui-success-darker)}ng-ui-checkbox:focus{outline:none}ng-ui-checkbox:focus i{color:var(--ng-ui-primary)}ng-ui-checkbox:focus.ng-ui-checkbox-animated i{animation-name:fa-beat;animation-iteration-count:1;animation-duration:.45s;--fa-beat-scale: 1.45}ng-ui-checkbox.ng-ui-checkbox-after{flex-direction:row-reverse}ng-ui-checkbox.ng-ui-checkbox-disabled{color:var(--ng-ui-neutral);cursor:not-allowed}ng-ui-checkbox.ng-ui-checkbox-disabled .ng-ui-checkbox-label{cursor:not-allowed}ng-ui-checkbox .ng-ui-checkbox-label{-webkit-user-select:auto;user-select:auto;cursor:pointer}ng-ui-checkbox-group{display:flex;transition:color .75s}ng-ui-checkbox-group.ng-ui-form-normal-validation.ng-touched.ng-invalid,ng-ui-checkbox-group.ng-ui-form-extended-validation.ng-touched.ng-invalid{color:var(--ng-ui-error-darker)}ng-ui-checkbox-group.ng-ui-form-extended-validation.ng-touched.ng-valid{color:var(--ng-ui-success-darker)}ng-ui-checkbox-group ng-ui-checkbox{align-self:flex-start}ng-ui-checkbox-group.ng-ui-checkbox-group-horizontal{flex-direction:row}ng-ui-checkbox-group.ng-ui-checkbox-group-vertical{flex-direction:column}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboxComponent, [{
    type: Component,
    args: [{
      selector: 'ng-ui-checkbox',
      standalone: true,
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: CheckboxComponent,
        multi: true
      }],
      host: {
        'role': 'checkbox',
        '[attr.aria-checked]': 'value === checkedValue',
        '[attr.aria-disabled]': '(disabled === true || _group?.disabled === true) ? "true" : "false"',
        '[attr.aria-labelledby]': '!!_fc?.label ? _fc.id + "-label" : _id',
        '[attr.tabindex]': '(disabled === true || _group?.disabled === true) ? null : 0',
        '[class.ng-ui-checkbox-after]': 'position === "right"',
        '[class.ng-ui-checkbox-animated]': '_group ? _group.animated === true : animated === true',
        '[class.ng-ui-checkbox-before]': 'position === "left"',
        '[class.ng-ui-checkbox-disabled]': 'disabled === true || _group?.disabled === true',
        '(keydown.space)': '_switch($event)',
        '(keydown.enter)': '_switch($event)',
        '(click)': '_switch()'
      },
      template: "<i class=\"far fa-fw ng-ui-checkbox-icon\" \r\n   [class.fa-check-square]=\"value === checkedValue && icon === 'tick'\"\r\n   [class.fa-solid]=\"value === checkedValue\"\r\n   [class.fa-square]=\"value !== checkedValue\"\r\n   [class.fa-times-square]=\"value === checkedValue && icon === 'cross'\"></i>\r\n<span class=\"ng-ui-checkbox-label\" [id]=\"_id\">\r\n  <ng-content></ng-content>\r\n</span>\r\n",
      styles: ["ng-ui-checkbox{display:inline-flex;align-items:center;cursor:pointer;transition:color .75s;-webkit-user-select:none;user-select:none;gap:calc(.75rem * var(--ng-ui-gap-factor))}ng-ui-checkbox .ng-ui-checkbox-icon{flex-shrink:0}ng-ui-checkbox.ng-ui-form-normal-validation.ng-touched.ng-invalid,ng-ui-checkbox.ng-ui-form-extended-validation.ng-touched.ng-invalid{color:var(--ng-ui-error-darker)}ng-ui-checkbox.ng-ui-form-extended-validation.ng-touched.ng-valid{color:var(--ng-ui-success-darker)}ng-ui-checkbox:focus{outline:none}ng-ui-checkbox:focus i{color:var(--ng-ui-primary)}ng-ui-checkbox:focus.ng-ui-checkbox-animated i{animation-name:fa-beat;animation-iteration-count:1;animation-duration:.45s;--fa-beat-scale: 1.45}ng-ui-checkbox.ng-ui-checkbox-after{flex-direction:row-reverse}ng-ui-checkbox.ng-ui-checkbox-disabled{color:var(--ng-ui-neutral);cursor:not-allowed}ng-ui-checkbox.ng-ui-checkbox-disabled .ng-ui-checkbox-label{cursor:not-allowed}ng-ui-checkbox .ng-ui-checkbox-label{-webkit-user-select:auto;user-select:auto;cursor:pointer}ng-ui-checkbox-group{display:flex;transition:color .75s}ng-ui-checkbox-group.ng-ui-form-normal-validation.ng-touched.ng-invalid,ng-ui-checkbox-group.ng-ui-form-extended-validation.ng-touched.ng-invalid{color:var(--ng-ui-error-darker)}ng-ui-checkbox-group.ng-ui-form-extended-validation.ng-touched.ng-valid{color:var(--ng-ui-success-darker)}ng-ui-checkbox-group ng-ui-checkbox{align-self:flex-start}ng-ui-checkbox-group.ng-ui-checkbox-group-horizontal{flex-direction:row}ng-ui-checkbox-group.ng-ui-checkbox-group-vertical{flex-direction:column}\n"]
    }]
  }], null, {
    animated: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    checkedValue: [{
      type: Input
    }],
    icon: [{
      type: Input
    }],
    position: [{
      type: Input
    }],
    uncheckedValue: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    change: [{
      type: Output
    }]
  });
})();
class CheckboxGroupComponent extends BaseFormControl {
  constructor() {
    super(...arguments);
    this.animated = true;
    this.gap = '0';
    this.orientation = 'vertical';
    this.change = new EventEmitter();
    /******************************************************** VARIABLES ********************************************************/
    this._value = null;
  }
  get value() {
    return this._value;
  }
  set value(value) {
    this._value = value;
    this._updateChildrenValue();
  }
  /******************************************************** VARIABLES ********************************************************/
  /** */
  ngAfterContentInit() {
    this.value && this._updateChildrenValue();
  }
  /******************************************************** CONTROL VALUE ACCESSOR ********************************************************/
  /**
   *
   * @param obj
   */
  writeValue(obj) {
    this.value = obj || [];
  }
  /******************************************************** INTERNAL ********************************************************/
  /** */
  _updateValue() {
    this._value = this.children.filter(c => c.value === c.checkedValue).map(c => c.checkedValue);
    this._propagateChange();
  }
  /******************************************************** PRIVATE ********************************************************/
  /** */
  // TODO: Should be in BaseFormControl
  _propagateChange() {
    this._onChange && this._onChange(this.value);
    this.change.emit(this.value);
  }
  /** */
  _updateChildrenValue() {
    if (this.children) {
      this.children.forEach(c => c._updateValue(this.value.includes(c.checkedValue) === true ? c.checkedValue : c.uncheckedValue));
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵCheckboxGroupComponent_BaseFactory;
      return function CheckboxGroupComponent_Factory(t) {
        return (ɵCheckboxGroupComponent_BaseFactory || (ɵCheckboxGroupComponent_BaseFactory = i0.ɵɵgetInheritedFactory(CheckboxGroupComponent)))(t || CheckboxGroupComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: CheckboxGroupComponent,
      selectors: [["ng-ui-checkbox-group"]],
      contentQueries: function CheckboxGroupComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, CheckboxComponent, 4);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
        }
      },
      hostAttrs: ["role", "group"],
      hostVars: 6,
      hostBindings: function CheckboxGroupComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵstyleProp("gap", ctx.gap);
          i0.ɵɵclassProp("ng-ui-checkbox-group-horizontal", ctx.orientation === "horizontal")("ng-ui-checkbox-group-vertical", ctx.orientation === "vertical");
        }
      },
      inputs: {
        animated: [2, "animated", "animated", booleanAttribute],
        gap: "gap",
        orientation: "orientation",
        value: "value"
      },
      outputs: {
        change: "change"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: CheckboxGroupComponent,
        multi: true
      }]), i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      ngContentSelectors: _c0,
      decls: 1,
      vars: 0,
      template: function CheckboxGroupComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵprojectionDef();
          i0.ɵɵprojection(0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboxGroupComponent, [{
    type: Component,
    args: [{
      selector: 'ng-ui-checkbox-group',
      template: '<ng-content></ng-content>',
      standalone: true,
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: CheckboxGroupComponent,
        multi: true
      }],
      host: {
        'role': 'group',
        '[class.ng-ui-checkbox-group-horizontal]': 'orientation === "horizontal"',
        '[class.ng-ui-checkbox-group-vertical]': 'orientation === "vertical"',
        '[style.gap]': 'gap'
      }
    }]
  }], null, {
    children: [{
      type: ContentChildren,
      args: [CheckboxComponent]
    }],
    animated: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    gap: [{
      type: Input
    }],
    orientation: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    change: [{
      type: Output
    }]
  });
})();
const COMPONENTS = [CheckboxComponent, CheckboxGroupComponent];
class CheckboxModule {
  static {
    this.ɵfac = function CheckboxModule_Factory(t) {
      return new (t || CheckboxModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: CheckboxModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(CheckboxModule, [{
    type: NgModule,
    args: [{
      imports: COMPONENTS,
      exports: COMPONENTS
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { COMPONENTS, CheckboxComponent, CheckboxGroupComponent, CheckboxModule };
