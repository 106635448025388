import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';
import { Application, Notification, ApplicationUpsertData } from '../models';
import { NgStore } from '@sfpd/ng-store';
import { AppState } from '@app/store';
import { HubService } from '@app/decorators';

@Injectable({
  providedIn: 'root'
})
@HubService()
export class ApplicationService {

  /********************************************************************** VARIABLES **********************************************************************/

  private readonly ROUTE: string = '/api/private/applications';

  /********************************************************************** LIFE CYCLE **********************************************************************/

  constructor(private _store: NgStore<AppState>) { }

  /********************************************************************** PUBLIC **********************************************************************/

  /** */
  public load(): Observable<boolean> {
    return this._store.loadAllEntities(this.ROUTE, s => s.applications).pipe(switchMap(() => this._store.isLoaded(s => s.applications)));
  }

  /** */
  public reset(id: number): Observable<Application> {
    return this._store.putEntityByKey(`${this.ROUTE}/reset`, s => s.applications, id, { id });
  }

  /** */
  public upsert(data: ApplicationUpsertData): Observable<Application> {
    return this._store.putEntityByKey(this.ROUTE, s => s.applications, data.id, data);
  }

  /********************************************************************** HUB **********************************************************************/

  /**
   * 
   * @param data
   */
  public onApplicationResetted(id: number) {
    this._store.updateValuesBy<Notification>(
      s => s.notifications,
      n => n.audience != null && n.audience.includes(id),
      n => n.audience = n.audience!.filter(a => a !== id)
    );

    this._store.removeValuesBy(s => s.notifications, n => n.audience?.length === 0);
  }

  /**
   * 
   * @param data
   */
  public onApplicationUpserted(data: Application) {
    this._store.upsertValue(s => s.applications, data);
  }

}
