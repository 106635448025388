import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Notification } from '@app/models';
import { NotificationService } from '@app/services';
import { AppState } from '@app/store';
import { Entity, NgStoreContainerComponent, StoreComponent } from '@sfpd/ng-store';
import { ButtonComponent, ButtonGroupComponent } from '@sfpd/ng-ui/button';
import { CheckboxComponent } from '@sfpd/ng-ui/checkbox';
import { DatePickerModule } from '@sfpd/ng-ui/date-picker';
import { DialogComponent, DialogContentComponent } from '@sfpd/ng-ui/dialog';
import { IconComponent } from '@sfpd/ng-ui/icon';
import { MenuComponent, MenuItemComponent, MenuTriggerComponent } from '@sfpd/ng-ui/menu';
import { ColumnFilterExtractor, ColumnValueComparer, TableBodyEmptyRowDirective, TableBodyRowDirective, TableComponent, TableHeaderComponent, TableHeaderRowDirective } from '@sfpd/ng-ui/table';
import { ToasterService } from '@sfpd/ng-ui/toaster';
import { TooltipDirective } from '@sfpd/ng-ui/tooltip';
import { Observable, finalize, of, take } from 'rxjs';
import { ApplicationPipe, UserPipe } from '../../pipes';
import { NotificationUpsertFormComponent } from '../notification-upsert-form/notification-upsert-form.component';
import { NotificationComponent } from '../notification/notification.component';
import { TogglableComponent } from '../togglable/togglable.component';

@Component({
  selector: 'noti-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  standalone: true,
  imports: [
    ApplicationPipe,
    ButtonComponent,
    ButtonGroupComponent,
    CheckboxComponent,
    CommonModule,
    DatePickerModule,
    DialogComponent,
    DialogContentComponent,
    FormsModule,
    IconComponent,
    MenuComponent,
    MenuItemComponent,
    MenuTriggerComponent,
    NgStoreContainerComponent,
    NotificationComponent,
    NotificationUpsertFormComponent,
    TableBodyEmptyRowDirective,
    TableBodyRowDirective,
    TableComponent,
    TableHeaderComponent,
    TableHeaderRowDirective,
    TogglableComponent,
    TooltipDirective,
    UserPipe
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserPipe, ApplicationPipe]
})
export class NotificationsComponent extends StoreComponent<AppState, Notification[]> implements OnInit {

  /******************************************************** BINDINGS ********************************************************/

  @ViewChild('deleteDialog')
  public deleteNotificationDialog!: DialogComponent;

  @ViewChild('upsertNotificationDialog')
  public upsertNotificationDialog!: DialogComponent;

  /******************************************************** VARIABLES ********************************************************/

  public appFilterExtractor: ColumnFilterExtractor<Notification> = n => [this._applicationPipe.transform(n.createdById)]
  public appValueComparer: ColumnValueComparer<Notification> = (n1, n2, _, direction) => this._applicationPipe.transform(n1.createdById).localeCompare(this._userPipe.transform(n2.createdById)) * (direction === 'ascending' ? 1 : -1);
  public autoRefresh = true;
  public duplicateId: number | null = null;
  public from!: Date;
  public to!: Date;
  public userFilterExtractor: ColumnFilterExtractor<Notification> = n => [this._userPipe.transform(n.userId)];
  public userValueComparer: ColumnValueComparer<Notification> = (n1, n2, _, direction) => this._userPipe.transform(n1.userId).localeCompare(this._userPipe.transform(n2.userId)) * (direction === 'ascending' ? 1 : -1);

  /******************************************************** LIFE CYCLE ********************************************************/

  public current$: Observable<Entity<Notification> | null> = of(null);

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(
    private _notificationService: NotificationService,
    private _userPipe: UserPipe,
    private _toasterService: ToasterService,
    private _applicationPipe: ApplicationPipe
  ) {
    super();
  }

  public ngOnInit() {
    this.from = new Date();
    this.to = new Date();

    this.from.setMonth(this.from.getMonth() - 1);

    this.setObservables();
  }

  /******************************************************** VARIABLES ********************************************************/

  /** */
  public deleteNotification(id: number): void {
    this._notificationService
      .deleteNotification(id)
      .pipe(finalize(() => this.deleteNotificationDialog.close()))
      .subscribe({
        error: err => this._toasterService.error(err)
      })
  }

  /** */
  public openDuplicateDialog(id: number) {
    this.duplicateId = id;
    this.upsertNotificationDialog.open();
  }

  /** */
  public setCurrent(id: number) {
    this.current$ = this._store.selectEntityByKey(s => s.notifications, id);
  }

  /** */
  public setObservables() {
    const from = new Date(this.from);
    const to = new Date(this.to);

    from.setHours(0, 0, 0, 0);
    to.setHours(23, 59, 59, 999);

    this.query$ = this._notificationService.loadNotifications(this.from, this.to);

    let notifications$ = this._store.selectValuesBy(
      s => s.notifications,
      s => s.createdAt.getTime() >= from.getTime() && s.createdAt.getTime() <= to.getTime()
    );

    if (this.autoRefresh === false) {
      notifications$ = notifications$.pipe(take(1));
    }

    this.data$ = notifications$;
  }

  /******************************************************** PUBLIC ********************************************************/

  /** */
  public showUpsertNotificationDialog() {
    this.upsertNotificationDialog.open();
  }
}
