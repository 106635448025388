import { createEntities, Entities } from '@sfpd/ng-store';
import { Application, Notification, User } from '../models';

export type AppState = {
  applications: Entities<Application>
  notifications: Entities<Notification>
  users: Entities<User>
}

export const initial: AppState = {
  applications: createEntities(),
  notifications: createEntities(),
  users: createEntities()
}
