import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { UserService } from '@app/services';
import { AppSettingsService } from '@sfpd/ng-app-settings';
import { IconComponent } from '@sfpd/ng-ui/icon';
import { AppSettings } from '../../models';

@Component({
  selector: 'noti-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    IconComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent {

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(
    public userService: UserService,
    public appDataService: AppSettingsService<AppSettings>
  ) { }

}
