import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Notification, NotificationUpsertData } from '@app/models';
import { NotificationService } from '@app/services';
import { AppState } from '@app/store';
import { jsonValidator } from '@app/validators';
import { NgStoreModule, StoreComponent } from '@sfpd/ng-store';
import { ButtonComponent, ButtonGroupComponent } from '@sfpd/ng-ui/button';
import { DialogComponent } from '@sfpd/ng-ui/dialog';
import { FormComponent, FormControlComponent, FormControlsComponent } from '@sfpd/ng-ui/form';
import { ToasterService } from '@sfpd/ng-ui/toaster';
import { Observable, map, of } from 'rxjs';
import { ApplicationPickerComponent } from '../application-picker/application-picker.component';
import { UserPickerComponent } from '../user-picker/user-picker.component';

type FormType = {
  agent: FormControl<number | null>;
  createdBy: FormControl<number | null>;
  audience: FormControl<number[] | null>;
  data: FormControl<string | null>;
}

@Component({
  selector: 'noti-notification-upsert-form',
  templateUrl: './notification-upsert-form.component.html',
  styleUrls: ['./notification-upsert-form.component.scss'],
  standalone: true,
  imports: [
    ApplicationPickerComponent,
    AsyncPipe,
    ButtonComponent,
    ButtonGroupComponent,
    FormComponent,
    FormControlComponent,
    FormControlsComponent,
    NgStoreModule,
    ReactiveFormsModule,
    UserPickerComponent,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotificationUpsertFormComponent extends StoreComponent<AppState, Notification | null> implements OnInit {

  /******************************************************** SERVICES ********************************************************/

  private readonly _fb = inject(FormBuilder);
  private readonly _toastService = inject(ToasterService);
  private readonly _notificationService = inject(NotificationService);
  public readonly dialog = inject(DialogComponent);

  /******************************************************** BINDINGS ********************************************************/

  @Input()
  public sourceId: number | null = null;

  /******************************************************** VARIABLES ********************************************************/

  public form!: FormGroup<FormType>;

  /******************************************************** OBSERVABLE ********************************************************/

  public processing$: Observable<boolean> = this._store.select(s => s.notifications).pipe(map(entities => entities.adding));

  /******************************************************** LIFE CYCLE ********************************************************/

  public ngOnInit(): void {
    this.form = this._fb.group({
      agent: this._fb.control<number | null>(null, Validators.required),
      createdBy: this._fb.control<number | null>(null, Validators.required),
      audience: this._fb.control<number[] | null>([], Validators.required),
      data: this._fb.control<string | null>(null, [Validators.required, jsonValidator()])
    });

    if (this.sourceId) {
      this.query$ = this._notificationService.loadNotification(this.sourceId);
      this.data$ = this._store.selectValueByKey(s => s.notifications, this.sourceId);
    }
    else {
      this.query$ = of(null);
      this.data$ = of(null);
    }
  }

  /******************************************************** LIFE CYCLE ********************************************************/

  /** */
  public setForm(notification: Notification | null) {
    if (this.sourceId && notification !== null) {
      this.form.get('data')?.reset(JSON.stringify(notification.data));
      this.form.get('createdBy')?.reset(notification.createdById);
      this.form.get('audience')?.reset(notification.audience)
      this.form.get('agent')?.reset(notification.userId);
    }
  }

  /** */
  public upsert() {
    const data: NotificationUpsertData = {
      audience: this.form.value.audience!,
      createdById: this.form.value.createdBy!,
      data: this.form.value.data!,
      userId: this.form.value.agent!
    };

    this._notificationService
      .upsert(data)
      .subscribe({
        next: () => {
          this._toastService.success('Notification created!')
          this.dialog.close();
        },
        error: err => this._toastService.error(err)
      })
  }

}
