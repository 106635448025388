import * as i0 from '@angular/core';
import { InjectionToken, ElementRef, inject, signal, booleanAttribute, Component, ChangeDetectionStrategy, ViewEncapsulation, Input, NgModule } from '@angular/core';
const NG_UI_ICON_OPTIONS = new InjectionToken('ng-ui-icon-options');
class IconComponent {
  constructor() {
    /****************************************************************** SERVICES ******************************************************************/
    this.elementRef = inject(ElementRef);
    this._iconOptions = inject(NG_UI_ICON_OPTIONS, {
      optional: true
    });
    /****************************************************************** BINDINGS ******************************************************************/
    this.animation = 'none';
    this.animationDelay = null;
    this.animationDuration = null;
    this.brush = this._iconOptions?.defaultIconBrush ?? 'regular';
    this.color = null;
    this.fixedWidth = true;
    this.name = null;
    this.primaryColor = null;
    this.secondaryColor = null;
    /****************************************************************** SIGNALS ******************************************************************/
    this.className = signal(null);
  }
  /****************************************************************** LIFE CYCLE ******************************************************************/
  /** */
  ngOnInit() {
    this._setClasses();
  }
  /** */
  ngOnChanges() {
    this._setClasses();
  }
  /****************************************************************** PRIVATE ******************************************************************/
  /** */
  _setClasses() {
    const classes = [`fa-${this.brush}`];
    if (this.fixedWidth === true) {
      classes.push('fa-fw');
    }
    if (this.name !== null) {
      classes.push(`fa-${this.name}`);
    } else {
      classes.push('fa');
    }
    if (this.animation && this.animation !== 'none') {
      classes.push(`fa-${this.animation}`);
    }
    this.className.set(classes.join(' '));
  }
  static {
    this.ɵfac = function IconComponent_Factory(t) {
      return new (t || IconComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: IconComponent,
      selectors: [["ng-ui-icon"], ["button", "ng-ui-icon", ""]],
      hostVars: 18,
      hostBindings: function IconComponent_HostBindings(rf, ctx) {
        if (rf & 2) {
          i0.ɵɵattribute("role", ctx.elementRef.nativeElement.tagName === "NG-UI-ICON" ? "img" : null)("alt", ctx.elementRef.nativeElement.tagName === "NG-UI-ICON" ? ctx.name : null);
          i0.ɵɵclassMap(ctx.className());
          i0.ɵɵstyleProp("--fa-primary-color", ctx.primaryColor)("--fa-secondary-color", ctx.secondaryColor)("--fa-animation-delay", ctx.animationDelay || "0")("--fa-animation-duration", ctx.animationDuration || null)("--ng-ui-icon-normal", "var(--ng-ui-" + ctx.color + ")")("--ng-ui-icon-darker", "var(--ng-ui-" + ctx.color + "-darker)")("--ng-ui-icon-light", "var(--ng-ui-" + ctx.color + "-ultra-light)");
        }
      },
      inputs: {
        animation: "animation",
        animationDelay: "animationDelay",
        animationDuration: "animationDuration",
        brush: "brush",
        color: "color",
        fixedWidth: [2, "fixedWidth", "fixedWidth", booleanAttribute],
        name: "name",
        primaryColor: "primaryColor",
        secondaryColor: "secondaryColor"
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 0,
      vars: 0,
      template: function IconComponent_Template(rf, ctx) {},
      styles: ["button[ng-ui-icon]{cursor:pointer;width:calc(1.75em + 3px);height:calc(1.75em + 3px);border-radius:50%}button[ng-ui-icon][disabled]{cursor:not-allowed}button[ng-ui-icon]:not([disabled]):focus,button[ng-ui-icon]:not([disabled]):hover{outline:none;background:var(--ng-ui-neutral-ultra-light)}button[ng-ui-icon]:not([disabled]):active{outline:none;background:var(--ng-ui-neutral-super-light)}button[ng-ui-icon]:focus,button[ng-ui-icon]:hover{color:var(--ng-ui-icon-darker);background:var(--ng-ui-icon-light);--fa-primary-color: var(--ng-ui-icon-darker);--fa-secondary-color: var(--ng-ui-icon-darker)}button[ng-ui-icon]:active{background:var(--ng-ui-icon-light)}ng-ui-icon,button[ng-ui-icon]{display:inline-block;background-color:transparent;border-width:0;color:var(--ng-ui-icon-normal);--fa-primary-color: var(--ng-ui-icon-normal);--fa-secondary-color: var(--ng-ui-icon-normal)}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconComponent, [{
    type: Component,
    args: [{
      selector: 'ng-ui-icon,button[ng-ui-icon]',
      template: '',
      host: {
        '[attr.role]': 'elementRef.nativeElement.tagName === "NG-UI-ICON" ? "img" : null',
        '[attr.alt]': 'elementRef.nativeElement.tagName === "NG-UI-ICON" ? name : null',
        '[class]': 'className()',
        '[style.--fa-primary-color]': 'primaryColor',
        '[style.--fa-secondary-color]': 'secondaryColor',
        '[style.--fa-animation-delay]': 'animationDelay || "0"',
        '[style.--fa-animation-duration]': 'animationDuration || null',
        '[style.--ng-ui-icon-normal]': '"var(--ng-ui-" + color + ")"',
        '[style.--ng-ui-icon-darker]': '"var(--ng-ui-" + color + "-darker)"',
        '[style.--ng-ui-icon-light]': '"var(--ng-ui-" + color + "-ultra-light)"'
      },
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush,
      encapsulation: ViewEncapsulation.None,
      styles: ["button[ng-ui-icon]{cursor:pointer;width:calc(1.75em + 3px);height:calc(1.75em + 3px);border-radius:50%}button[ng-ui-icon][disabled]{cursor:not-allowed}button[ng-ui-icon]:not([disabled]):focus,button[ng-ui-icon]:not([disabled]):hover{outline:none;background:var(--ng-ui-neutral-ultra-light)}button[ng-ui-icon]:not([disabled]):active{outline:none;background:var(--ng-ui-neutral-super-light)}button[ng-ui-icon]:focus,button[ng-ui-icon]:hover{color:var(--ng-ui-icon-darker);background:var(--ng-ui-icon-light);--fa-primary-color: var(--ng-ui-icon-darker);--fa-secondary-color: var(--ng-ui-icon-darker)}button[ng-ui-icon]:active{background:var(--ng-ui-icon-light)}ng-ui-icon,button[ng-ui-icon]{display:inline-block;background-color:transparent;border-width:0;color:var(--ng-ui-icon-normal);--fa-primary-color: var(--ng-ui-icon-normal);--fa-secondary-color: var(--ng-ui-icon-normal)}\n"]
    }]
  }], null, {
    animation: [{
      type: Input
    }],
    animationDelay: [{
      type: Input
    }],
    animationDuration: [{
      type: Input
    }],
    brush: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    fixedWidth: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    name: [{
      type: Input
    }],
    primaryColor: [{
      type: Input
    }],
    secondaryColor: [{
      type: Input
    }]
  });
})();
const COMPONENTS = [IconComponent];
class IconModule {
  static {
    this.ɵfac = function IconModule_Factory(t) {
      return new (t || IconModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: IconModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(IconModule, [{
    type: NgModule,
    args: [{
      imports: COMPONENTS,
      exports: COMPONENTS
    }]
  }], null, null);
})();
const withIcons = data => [{
  provide: NG_UI_ICON_OPTIONS,
  useValue: data
}];

/**
 * Generated bundle index. Do not edit.
 */

export { COMPONENTS, IconComponent, IconModule, NG_UI_ICON_OPTIONS, withIcons };
