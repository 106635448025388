import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
// TODO: UPdate with MonoTypeOperatorFunction and lift
export function mapToError() {
    return (source) => {
        return source
            .pipe(map(() => null), catchError(err => of(err)));
    };
}
;
