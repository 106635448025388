import { Injectable } from '@angular/core';
import { AppState } from '@app/store';
import { OAuthService } from 'angular-oauth2-oidc';
import { Observable, of, switchMap, tap } from 'rxjs';
import { User } from '../models';
import { NgStore } from '@sfpd/ng-store';
import { HubService } from '@app/decorators';

@Injectable({
  providedIn: 'root'
})
@HubService()
export class UserService {

  /********************************************************************** VARIABLES **********************************************************************/

  private readonly ROUTE: string = '/api/private/users';

  private _currentUser$!: Observable<User | null>;

  /********************************************************************** ACCESSORS **********************************************************************/

  public get currentUser$(): Observable<User | null> {
    return this._currentUser$;
  }

  /********************************************************************** LIFE CYCLE **********************************************************************/

  constructor(
    private _store: NgStore<AppState>,
    private _oauthService: OAuthService
  ) { }

  /********************************************************************** PUBLIC **********************************************************************/

  /** */
  public load(): Observable<boolean> {
    return this._store
      .loadAllEntities(this.ROUTE, s => s.users)
      .pipe(
        switchMap(() => this._store.isLoaded(s => s.users)),
        tap(() => this._setCurrentUser())
      );
  }

  /**
   * 
   * @param id
   */
  public switchPermission(id: number): Observable<User> {
    return this._store.putEntityByKey(`${this.ROUTE}/permission`, s => s.users, id, { id });
  }

  /********************************************************************** PRIVATE **********************************************************************/

  /** */
  private _setCurrentUser() {
    const upn: string | null = (this._oauthService.getIdentityClaims() as any)["upn"]?.toLowerCase() || null;

    if (upn) {
      this._currentUser$ = this._store.selectValueBy(s => s.users, u => u.email.toLowerCase() === upn);
    }
    else {
      this._currentUser$ = of(null);
    }
  }

  /********************************************************************** HUB **********************************************************************/

  /**
   * 
   * @param user
   */
  public onUserUpserted(user: User) {
    this._store.upsertValue(s => s.users, user);
  }
}
