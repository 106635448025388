import { map } from 'rxjs/operators';
export function mapToBoolean(ignoreArrays = false) {
    return (source) => {
        return source
            .pipe(map(value => {
            if (ignoreArrays === true || Array.isArray(value) === false) {
                return !!value;
            }
            const array = value.slice(0);
            return !array.map(item => !!item).includes(false);
        }));
    };
}
;
