import { formatDate } from '@angular/common';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

export enum Direction {
  Backward = -1,
  Forward = 1
}

@Injectable({
  providedIn: 'root',
})
export class DateService {

  /******************************************************** VARIABLES ********************************************************/

  private readonly PATTERN: string = 'yyyy-MM-ddTHH:mm:ss.SSS';
  private readonly ISO8601: RegExp = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(@Inject(LOCALE_ID) private _locale: string) { }

  /******************************************************** PUBLIC ********************************************************/

  /**
   * 
   * @param d1
   * @param d2
   */
  public areDateComponentsEqual(d1: Date, d2: Date): boolean {
    return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear();
  }

  /**
   * 
   * @param body
   */
  public convertToDate(body: any): any {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];

      if (this._isIso8601(value)) {
        body[key] = new Date(value);
      }
      else if (typeof value === 'object') {
        this.convertToDate(value);
      }
    }
  }

  /**
   * 
   * @param body
   */
  public convertToString(body: any): any {
    if (body === null || body === undefined) {
      return body;
    }

    if (typeof body !== 'object') {
      return body;
    }

    for (const key of Object.keys(body)) {
      const value = body[key];

      if (value instanceof Date) {
        body[key] = this.toJSONLocal(value);
      }
      else if (typeof value === 'object') {
        this.convertToString(value);
      }
    }
  }

  /**
   * 
   * @param date
   */
  public toJSONLocal(date: Date): string | null {
    if (!date) {
      return null;
    }

    return formatDate(date, this.PATTERN, this._locale);
  }

  /******************************************************** PRIVATE ********************************************************/

  /**
   * 
   * @param value
   */
  private _isIso8601(value: any): boolean {
    if (value === null || value === undefined) {
      return false;
    }

    return this.ISO8601.test(value);
  }
}
