<img class="logo"
     alt="logo"
     src="/assets/logo.svg" />
<div class="flex-1 title">Noti-NET {{ appDataService.settings.version }}</div>
<div class="flex-1 flex-row flex-jcc gap2">
  <a #notif="routerLinkActive"
     class="item"
     routerLink="notifications"
     routerLinkActive="active">
    <ng-ui-icon name="bell"
                [brush]="notif.isActive ? 'solid' : 'regular'"></ng-ui-icon>
    Notifications
  </a>
  <a #app="routerLinkActive"
     class="item"
     routerLink="applications"
     routerLinkActive="active">
    <ng-ui-icon name="circle-nodes"
                [brush]="app.isActive ? 'solid' : 'regular'"></ng-ui-icon>
    Applications
  </a>
  <a #admin="routerLinkActive"
     class="item"
     routerLink="permissions"
     routerLinkActive="active">
    <ng-ui-icon name="user-crown"
                [brush]="admin.isActive ? 'solid' : 'regular'"></ng-ui-icon>
    Permissions
  </a>
</div>
<div class="flex-1 flex-row flex-jce">
  <div class="avatar"
       [style.backgroundImage]="'url(' + user.pictureUrl + ')'"
       *ngIf="userService.currentUser$ | async as user"></div>
</div>
