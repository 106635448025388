import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { AppComponent } from '@app/components/app/app.component';
import { ErrorComponent } from '@app/components/error/error.component';
import { ROUTES } from '@app/configuration';
import { FormErrorParserService, HttpClient, TranslatorService } from '@app/services';
import { initial } from '@app/store';
import { provideAppSettings } from '@sfpd/ng-app-settings';
import { provideStore } from '@sfpd/ng-store';
import { provideNgUi, withTranslations } from '@sfpd/ng-ui/core';
import { withDialogs } from '@sfpd/ng-ui/dialog';
import { withFormErrorParser, withForms } from '@sfpd/ng-ui/form';
import { LoaderComponent } from '@sfpd/ng-ui/loader';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { environment } from './environments/environment';

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(ROUTES),
    provideHttpClient(
      withInterceptorsFromDi()
    ),
    provideAnimations(),
    provideAppSettings({
      production: environment.production
    }),
    provideOAuthClient({
      resourceServer: {
        allowedUrls: ['/', window.origin],
        sendAccessToken: true
      }
    }),
    provideNgUi(
      withTranslations(TranslatorService),
      withForms({
        requiredIndicatorType: 'asterisk',
        maxWidth: '100%'
      }),
      withDialogs({
        mode: 'alert'
      }),
      withFormErrorParser(FormErrorParserService)
    ),
    provideStore({
      httpClientType: HttpClient,
      initialValue: initial,
      loaderComponent: LoaderComponent,
      initialLoaderSize: '10rem',
      defaultLoaderText: () => 'Loading...',
      errorComponent: ErrorComponent
    })
  ]
});
