<h1 class="flex-row flex-aic gap1">
  <ng-ui-icon name="user-crown"
              brush="regular"></ng-ui-icon>
  Permissions
</h1>

<div class="box mt2 flex-row flex-aic flex-jcc">
  <ngs-container [query$]="query$"
                 [data$]="data$"
                 loaderSize="10rem">
    <ng-template let-users>
      <ng-ui-table [source]="users"
                   displayTopPagination="false">
        <tr *ngUiTableHeaderRow>
          <th scope="col"
              ngUiTableHeader="id"
              sortable="true"
              sorted="true"
              sortDirection="ascending"
              filterable="true"
              [columnValueComparer]="identityValueComparer"
              [columnFilterExtractor]="identityFilterExtractor">Name</th>
          <th scope="col"
              class="action"
              ngUiTableHeader="isAdmin"
              sortable="true">Action</th>
        </tr>
        <tr *ngUiTableBodyRow="let user">
          <td>
            <div class="flex-row flex-aic gap1">
              <ng-ui-icon name="lock-open"
                          color="success"
                          brush="solid"
                          *ngIf="user.isAdmin === true"></ng-ui-icon>
              <ng-ui-icon name="lock"
                          color="error"
                          brush="solid"
                          *ngIf="user.isAdmin === false"></ng-ui-icon>
              <div>{{ user.id | user }}</div>
            </div>
          </td>
          <td>
            <button ng-ui-icon
                    color="primary"
                    tooltipColor="primary"
                    [name]="(processing$ | async) ? 'spinner' : 'arrow-right-arrow-left'"
                    [animation]="(processing$ | async) ? 'spin' : 'none'"
                    [tooltip]="user.isAdmin === true ? 'Remove access' : 'Grant access'"
                    [disabled]="processing$ | async"
                    (click)="switch(user.id)"></button>
          </td>
        </tr>
        <tr *ngUiTableBodyEmptyRow>
          <td colspan="2"
              class="text-center">No data</td>
        </tr>
      </ng-ui-table>
    </ng-template>
  </ngs-container>
</div>
