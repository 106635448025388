import * as i0 from '@angular/core';
import { ElementRef, inject, EventEmitter, Directive, Input, Output, NgModule } from '@angular/core';
import { DestroyableComponent } from '@sfpd/ng-ui/core';
import { debounceTime, takeUntil, fromEvent } from 'rxjs';
class DebouncedInputDirective extends DestroyableComponent {
  constructor() {
    super(...arguments);
    /********************************************************************** SERVICES **********************************************************************/
    this._elementRef = inject(ElementRef);
    this.debouncedInput = new EventEmitter();
    /********************************************************************** VARIABLES **********************************************************************/
    this._time = 300;
  }
  /********************************************************************** BINDINGS **********************************************************************/
  get time() {
    return this._time;
  }
  set time(value) {
    this._time = Number(value) || 300;
  }
  /********************************************************************** LIFE CYCLE **********************************************************************/
  /** */
  ngOnInit() {
    fromEvent(this._elementRef.nativeElement, 'input').pipe(debounceTime(this.time), takeUntil(this._destroy$)).subscribe(e => this.debouncedInput.emit(e));
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵDebouncedInputDirective_BaseFactory;
      return function DebouncedInputDirective_Factory(t) {
        return (ɵDebouncedInputDirective_BaseFactory || (ɵDebouncedInputDirective_BaseFactory = i0.ɵɵgetInheritedFactory(DebouncedInputDirective)))(t || DebouncedInputDirective);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DebouncedInputDirective,
      selectors: [["input", "ng-ui-debounced-input", ""], ["textarea", "ng-ui-debounced-input", ""]],
      inputs: {
        time: [0, "ng-ui-debounced-input", "time"]
      },
      outputs: {
        debouncedInput: "debouncedInput"
      },
      standalone: true,
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DebouncedInputDirective, [{
    type: Directive,
    args: [{
      selector: 'input[ng-ui-debounced-input], textarea[ng-ui-debounced-input]',
      standalone: true
    }]
  }], null, {
    time: [{
      type: Input,
      args: ['ng-ui-debounced-input']
    }],
    debouncedInput: [{
      type: Output
    }]
  });
})();
const DIRECTIVES = [DebouncedInputDirective];
class DebouncedInputModule {
  static {
    this.ɵfac = function DebouncedInputModule_Factory(t) {
      return new (t || DebouncedInputModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: DebouncedInputModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DebouncedInputModule, [{
    type: NgModule,
    args: [{
      imports: DIRECTIVES,
      exports: DIRECTIVES
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, DebouncedInputDirective, DebouncedInputModule };
