import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, inject } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppState } from '@app/store';
import { NgStore } from '@sfpd/ng-store';
import { BaseFormControl } from '@sfpd/ng-ui/core';
import { SelectComponent, SelectOptionDirective } from '@sfpd/ng-ui/select';

@Component({
  selector: 'noti-application-picker',
  templateUrl: './application-picker.component.html',
  styleUrls: ['./application-picker.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    SelectComponent,
    SelectOptionDirective
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ApplicationPickerComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationPickerComponent extends BaseFormControl<number | number[] | null> implements ControlValueAccessor {

  /******************************************************** SERVICES ********************************************************/

  private readonly _store = inject<NgStore<AppState>>(NgStore);

  /******************************************************** BINDINGS ********************************************************/

  @Input()
  public multiple = false;

  /******************************************************** VARIABLES ********************************************************/

  public applications = this._store.getValues(s => s.applications);
  public value: number | number[] | null = null;

  /******************************************************** BASE FORM CONTROL ********************************************************/

  /** */
  public handleChange(value: number | number[]) {
    this._onChange && this._onChange(value);
  }

  /******************************************************** BASE FORM CONTROL ********************************************************/

  public writeValue(value: number | number[] | null): void {
    this.value = value;
  }
}
