import { UpperCasePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { ControlValueAccessor, FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppState } from '@app/store';
import { NgStore } from '@sfpd/ng-store';
import { BaseFormControl, FilterFunction, FormatterFunction, SorterFunction } from '@sfpd/ng-ui/core';
import { PickerComponent } from '@sfpd/ng-ui/picker';
import { User } from '../../models';

@Component({
  selector: 'noti-user-picker',
  templateUrl: './user-picker.component.html',
  styleUrls: ['./user-picker.component.scss'],
  standalone: true,
  imports: [
    FormsModule,
    PickerComponent,
    UpperCasePipe
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UserPickerComponent,
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserPickerComponent extends BaseFormControl<number | null> implements ControlValueAccessor {

  /******************************************************** SERVICES ********************************************************/

  private readonly _store = inject<NgStore<AppState>>(NgStore);

  /******************************************************** VARIABLES ********************************************************/

  public filter: FilterFunction = (item: User) => [item.firstName, item.lastName, item.email, `${item.firstName} ${item.lastName}`, `${item.lastName} ${item.firstName}`];
  public formatter: FormatterFunction = (item: User) => `${item.firstName} ${item.lastName.toUpperCase()}`;
  public sorter: SorterFunction = (i1: User, i2: User) => `${i1.firstName} ${i2.lastName.toUpperCase()}`.localeCompare(`${i1.firstName} ${i2.lastName.toUpperCase()}`);
  public users = this._store.getValues(s => s.users);
  public value: User | null = null;

  /******************************************************** PUBLIC ********************************************************/

  /** */
  public picked(item: User): void {
    this._onChange && this._onChange(item?.id || null);
  }

  /******************************************************** BASE FORM CONTROL ********************************************************/

  public writeValue(value: number | null): void {
    this.value = value === null ? null : this._store.findValueByKey(s => s.users, value);
  }
}
