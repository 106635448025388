<form ng-ui-form
      maxWidth="100%"
      [formGroup]="form">
  <div ng-ui-form-control="Name">
    <input type="text"
           formControlName="name" />
  </div>
  <div ng-ui-form-control="Retention (in days)">
    <ng-ui-number formControlName="retention"></ng-ui-number>
  </div>
  <div ng-ui-form-controls>
    <div ng-ui-form-control="Authentication Key">
      <input type="text"
             formControlName="authenticationKey"
             [inputMask]="guidMask" />
    </div>
    <div ng-ui-form-control="Identification Key">
      <input type="text"
             formControlName="identificationKey"
             [inputMask]="guidMask" />
    </div>
  </div>
  <div ng-ui-form-controls>
    <div ng-ui-form-control="Azure client ID (pre-prod)">
      <input type="text"
             formControlName="preProdAzureClientId"
             [inputMask]="guidMask" />
    </div>
    <div ng-ui-form-control="Azure client secret (pre-prod)">
      <input type="text"
             formControlName="preProdAzureClientSecret" />
    </div>
  </div>
  <div ng-ui-form-controls>
    <div ng-ui-form-control="Azure client ID">
      <input type="text"
             formControlName="azureClientId"
             [inputMask]="guidMask" />
    </div>
    <div ng-ui-form-control="Azure client secret">
      <input type="text"
             formControlName="azureClientSecret" />
    </div>
  </div>
  <div class="mt1"
       ng-ui-button-group
       alignment="center">
    <button ng-ui-button
            size="medium"
            color="success"
            icon="check"
            [disabled]="form.invalid"
            [loading]="upserting"
            (click)="upsert()">
      <ng-container *ngIf="id === null">Create</ng-container>
      <ng-container *ngIf="id !== null">Update</ng-container>
    </button>
    <button ng-ui-button
            size="medium"
            color="error"
            icon="times"
            (click)="cancel.emit()">
      Cancel
    </button>
  </div>
</form>