import { Injectable } from "@angular/core";
import { filterFalsy, mapToBoolean } from "@sfpd/rxjs-plus";
import { bufferCount, concat, map, Observable, ReplaySubject, switchMap, tap, throwError } from "rxjs";
import { ApplicationService } from "./application.service";
import { AuthService } from "./auth.service";
import { ConnectionState, HubService } from "./hub.service";
import { UserService } from "./user.service";

export type ApplicationState = 'loading' | 'forbidden' | 'loaded';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  /******************************************************** VARIABLES ********************************************************/

  private _state: ReplaySubject<ApplicationState> = new ReplaySubject<ApplicationState>(1);
  private _state$: Observable<ApplicationState> = this._state.asObservable();

  /******************************************************** ACCESSORS ********************************************************/

  public get state$(): Observable<ApplicationState> {
    return this._state$;
  }

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(
    private _authService: AuthService,
    private _hubService: HubService,
    private _applicationService: ApplicationService,
    private _userService: UserService
  ) {
    this._state.next('loading');

    const isAdmin$ = concat(
      this._authService.login().pipe(filterFalsy()),
      this._userService.load().pipe(switchMap(() => this._userService.currentUser$.pipe(map(u => !!u?.isAdmin))))
    ).pipe(bufferCount(2), mapToBoolean());

    const rest$ = concat(
      this._applicationService.load(),
      this._hubService.ensureConnection().pipe(switchMap(() => this._hubService.state$.pipe(map(s => s === ConnectionState.Connected), filterFalsy())))
    )

    isAdmin$
      .pipe(switchMap(isAdmin => isAdmin === true ? rest$ : throwError(null)))
      .subscribe({
        next: state => this._state.next(state ? 'loaded' : 'forbidden'),
        error: () => this._state.next('forbidden')
      });
  }

}
