import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Application, ApplicationUpsertData } from '@app/models';
import { ApplicationService } from '@app/services';
import { AppState } from '@app/store';
import { NgStore } from '@sfpd/ng-store';
import { ButtonComponent, ButtonGroupComponent } from '@sfpd/ng-ui/button';
import { FormComponent, FormControlComponent, FormControlsComponent } from '@sfpd/ng-ui/form';
import { InputMaskDirective, InputMaskOptions, createMask } from '@sfpd/ng-ui/input-mask';
import { NumberComponent } from '@sfpd/ng-ui/number';
import { ToasterService } from '@sfpd/ng-ui/toaster';
import { finalize } from 'rxjs';

const enum Fields {
  AuthenticationKey = 'authenticationKey',
  AzureClientId = 'azureClientId',
  AzureClientSecret = 'azureClientSecret',
  IdentificationKey = 'identificationKey',
  Name = 'name',
  PreProdAzureClientId = 'preProdAzureClientId',
  PreProdAzureClientSecret = 'preProdAzureClientSecret',
  Retention = 'retention'
}

@Component({
  selector: 'noti-application-upsert-form',
  templateUrl: './application-upsert-form.component.html',
  styleUrls: ['./application-upsert-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormComponent,
    FormControlComponent,
    FormControlsComponent,
    ReactiveFormsModule,
    NumberComponent,
    InputMaskDirective,
    ButtonComponent,
    ButtonGroupComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationUpsertFormComponent implements OnInit {

  /******************************************************** BINDINGS ********************************************************/

  @Input()
  public get id(): number | null { return this._id; }
  public set id(value: number | null) {
    this._id = value;

    if (value === null) {
      this._resetForm(null);
    }
    else {
      this._resetForm(this._store.findValueByKey(s => s.applications, value));
    }
  }

  @Output()
  public cancel: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public add: EventEmitter<void> = new EventEmitter<void>();

  /******************************************************** VARIABLES ********************************************************/

  public form!: UntypedFormGroup;
  public guidMask: InputMaskOptions = createMask({
    regex: '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
    casing: 'lower'
  });
  public upserting = false;

  private _id: number | null = null;

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(
    private _fb: UntypedFormBuilder,
    private _applicationService: ApplicationService,
    private _store: NgStore<AppState>,
    private _toastService: ToasterService,
    private _cdr: ChangeDetectorRef
  ) { }

  public ngOnInit() {
    this._createForm();
  }

  /******************************************************** PUBLIC ********************************************************/

  /** */
  public upsert() {
    this.upserting = true;

    const data: ApplicationUpsertData = {
      id: this._id,
      authenticationKey: this.form.get(Fields.AuthenticationKey)?.value,
      azureClientId: this.form.get(Fields.AzureClientId)?.value,
      azureClientSecret: this.form.get(Fields.AzureClientSecret)?.value,
      identificationKey: this.form.get(Fields.IdentificationKey)?.value,
      name: this.form.get(Fields.Name)?.value,
      preProdAzureClientId: this.form.get(Fields.PreProdAzureClientId)?.value,
      preProdAzureClientSecret: this.form.get(Fields.PreProdAzureClientSecret)?.value,
      retention: this.form.get(Fields.Retention)?.value || null,
    }

    this._applicationService
      .upsert(data)
      .pipe(finalize(() => {
        this.upserting = false;
        this._cdr.markForCheck();
      }))
      .subscribe({
        next: () => this.add.emit(),
        error: err => this._toastService.error(err)
      })
  }

  /******************************************************** PRIVATE ********************************************************/

  /** */
  private _createForm() {
    if (!this.form) {
      this.form = this._fb.group({
        [Fields.AuthenticationKey]: [null, Validators.required],
        [Fields.AzureClientId]: [null, Validators.required],
        [Fields.AzureClientSecret]: [null, Validators.required],
        [Fields.IdentificationKey]: [null, Validators.required],
        [Fields.Name]: [null, Validators.required],
        [Fields.PreProdAzureClientId]: [null, Validators.required],
        [Fields.PreProdAzureClientSecret]: [null, Validators.required],
        [Fields.Retention]: [null]
      });
    }
  }

  /**
   *
   * @param application
   */
  public _resetForm(application: Application | null) {
    this._createForm();

    this.form.reset({
      [Fields.AuthenticationKey]: application?.authenticationKey || crypto.randomUUID(),
      [Fields.AzureClientId]: application?.azureClientId || null,
      [Fields.AzureClientSecret]: application?.azureClientSecret || null,
      [Fields.IdentificationKey]: application?.identificationKey || crypto.randomUUID(),
      [Fields.Name]: application?.name || null,
      [Fields.PreProdAzureClientId]: application?.preProdAzureClientId || null,
      [Fields.PreProdAzureClientSecret]: application?.preProdAzureClientSecret,
      [Fields.Retention]: application?.retention || null
    })
  }

}
