import * as i0 from '@angular/core';
import { ElementRef, inject, ChangeDetectorRef, EventEmitter, Directive, Input, Output, NgModule } from '@angular/core';

// TODO: Guidelines
class LayoutDirective {
  constructor() {
    /********************************************************************** SERVICES **********************************************************************/
    this._elementRef = inject(ElementRef);
    this._cdr = inject(ChangeDetectorRef);
    this.resized = new EventEmitter();
    /********************************************************************** VARIABLES **********************************************************************/
    this._layout = null;
    this._observer = null;
    this._previous = null;
    this._stops = [];
  }
  /********************************************************************** BINDINGS **********************************************************************/
  get layout() {
    return this._layout;
  }
  set layout(value) {
    this._layout = !!value ? value : null;
  }
  /********************************************************************** LIFE CYCLE **********************************************************************/
  /** */
  ngOnInit() {
    this._observer = new ResizeObserver(entries => this._setClass(entries[0].contentRect.width));
    this._observer.observe(this._elementRef.nativeElement);
  }
  /**
   *
   * @param changes
   */
  ngOnChanges() {
    this._setStops();
  }
  /** */
  ngOnDestroy() {
    this._observer?.unobserve(this._elementRef.nativeElement);
  }
  /********************************************************************** PRIVATE **********************************************************************/
  /**
   *
   * @param width
   */
  _setClass(width) {
    this.width = width;
    let c = '';
    for (let stop of this._stops) {
      if (width > stop.width) {
        break;
      } else {
        c = stop.class;
      }
    }
    if (!c && this._previous) {
      this._elementRef.nativeElement.classList.remove(this._previous);
    } else if (c && c !== this._previous) {
      if (this._previous) {
        this._elementRef.nativeElement.classList.remove(this._previous);
      }
      this._elementRef.nativeElement.classList.add(c);
    }
    this._previous = c;
    this.resized.emit(this.width);
    this._cdr.markForCheck();
  }
  /** */
  _setStops() {
    this._stops = [];
    if (this.layout !== null) {
      for (let c in this.layout) {
        this._stops.push({
          class: c,
          width: this.layout[c]
        });
      }
    }
    this._stops.sort((s1, s2) => s2.width - s1.width);
  }
  static {
    this.ɵfac = function LayoutDirective_Factory(t) {
      return new (t || LayoutDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: LayoutDirective,
      selectors: [["", "ngUiLayout", ""]],
      inputs: {
        layout: [0, "ngUiLayout", "layout"]
      },
      outputs: {
        resized: "resized"
      },
      exportAs: ["ngUiLayout"],
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayoutDirective, [{
    type: Directive,
    args: [{
      selector: '[ngUiLayout]',
      standalone: true,
      exportAs: 'ngUiLayout'
    }]
  }], null, {
    layout: [{
      type: Input,
      args: ['ngUiLayout']
    }],
    resized: [{
      type: Output
    }]
  });
})();
const DIRECTIVES = [LayoutDirective];
class LayoutModule {
  static {
    this.ɵfac = function LayoutModule_Factory(t) {
      return new (t || LayoutModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: LayoutModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(LayoutModule, [{
    type: NgModule,
    args: [{
      imports: DIRECTIVES,
      exports: DIRECTIVES
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, LayoutDirective, LayoutModule };
