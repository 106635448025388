import { HttpClient, HttpClientModule } from '@angular/common/http';
import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable, APP_INITIALIZER, NgModule, makeEnvironmentProviders } from '@angular/core';

const NG_APP_SETTINGS_CONFIGURATION = new InjectionToken('NG_APP_SETTINGS_CONFIGURATION');

function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item));
}
function mergeDeep(target, ...sources) {
    if (!sources.length) {
        return target;
    }
    const source = sources.shift();
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) {
                    Object.assign(target, { [key]: {} });
                }
                mergeDeep(target[key], source[key]);
            }
            else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return mergeDeep(target, ...sources);
}

class AppSettingsService {
    constructor() {
        /******************************************************** SERVICES ********************************************************/
        this._http = inject(HttpClient);
        this._config = inject(NG_APP_SETTINGS_CONFIGURATION);
    }
    /******************************************************** ACCESSORS ********************************************************/
    get settings() {
        return this._settings;
    }
    /******************************************************** PUBLIC ********************************************************/
    /** */
    async load() {
        this._settings = await this._http.get(`${this._config.root}/appsettings.json`).toPromise();
        if (!this._config.production) {
            try {
                const devSettings = await this._http.get(`${this._config.root}/appsettings.dev.json`).toPromise();
                mergeDeep(this._settings, devSettings);
            }
            catch {
                console.warn('The development version of the appsettings could not be retrieved!');
            }
        }
    }
}
AppSettingsService.ɵfac = function AppSettingsService_Factory(t) { return new (t || AppSettingsService)(); };
AppSettingsService.ɵprov = /*@__PURE__*/ i0.ɵɵdefineInjectable({ token: AppSettingsService, factory: AppSettingsService.ɵfac, providedIn: "root" });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSettingsService, [{
        type: Injectable,
        args: [{
                providedIn: "root"
            }]
    }], null, null); })();

class AppSettingsModule {
    /** */
    static forRoot(configuration) {
        const config = mergeDeep({ production: true, root: './assets/config' }, configuration);
        return {
            ngModule: AppSettingsModule,
            providers: [
                { provide: NG_APP_SETTINGS_CONFIGURATION, useValue: config },
                {
                    provide: APP_INITIALIZER,
                    useFactory: (acs) => () => acs.load(),
                    deps: [AppSettingsService],
                    multi: true
                }
            ]
        };
    }
}
AppSettingsModule.ɵfac = function AppSettingsModule_Factory(t) { return new (t || AppSettingsModule)(); };
AppSettingsModule.ɵmod = /*@__PURE__*/ i0.ɵɵdefineNgModule({ type: AppSettingsModule });
AppSettingsModule.ɵinj = /*@__PURE__*/ i0.ɵɵdefineInjector({ imports: [HttpClientModule] });
(function () { (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AppSettingsModule, [{
        type: NgModule,
        args: [{
                imports: [HttpClientModule],
                exports: []
            }]
    }], null, null); })();
(function () { (typeof ngJitMode === "undefined" || ngJitMode) && i0.ɵɵsetNgModuleScope(AppSettingsModule, { imports: [HttpClientModule] }); })();

const provideAppSettings = (config) => {
    const fullConfig = mergeDeep({ production: true, root: './assets/config' }, config);
    return makeEnvironmentProviders([
        AppSettingsService,
        {
            provide: NG_APP_SETTINGS_CONFIGURATION,
            useValue: fullConfig
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (acs) => () => acs.load(),
            deps: [AppSettingsService],
            multi: true
        }
    ]);
};

/**
 * Generated bundle index. Do not edit.
 */

export { AppSettingsModule, AppSettingsService, provideAppSettings };

