import * as i0 from '@angular/core';
import { EventEmitter, Directive, Output, HostListener, NgModule } from '@angular/core';

// TODO: Guidelines
class UsableDirective {
  constructor() {
    /********************************************************************** BINDINGS **********************************************************************/
    this.used = new EventEmitter();
  }
  onClick(event) {
    this.used.emit(event);
  }
  onEnter(event) {
    this.used.emit(event);
  }
  onSpace(event) {
    event.preventDefault();
    this.used.emit(event);
  }
  static {
    this.ɵfac = function UsableDirective_Factory(t) {
      return new (t || UsableDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: UsableDirective,
      selectors: [["", "ngUiUsable", ""]],
      hostVars: 1,
      hostBindings: function UsableDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("click", function UsableDirective_click_HostBindingHandler($event) {
            return ctx.onClick($event);
          })("keydown.enter", function UsableDirective_keydown_enter_HostBindingHandler($event) {
            return ctx.onEnter($event);
          })("keydown.space", function UsableDirective_keydown_space_HostBindingHandler($event) {
            return ctx.onSpace($event);
          });
        }
        if (rf & 2) {
          i0.ɵɵhostProperty("tabIndex", 0);
        }
      },
      outputs: {
        used: "used"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UsableDirective, [{
    type: Directive,
    args: [{
      selector: '[ngUiUsable]',
      standalone: true,
      host: {
        '[tabIndex]': '0'
      }
    }]
  }], null, {
    used: [{
      type: Output
    }],
    onClick: [{
      type: HostListener,
      args: ['click', ['$event']]
    }],
    onEnter: [{
      type: HostListener,
      args: ['keydown.enter', ['$event']]
    }],
    onSpace: [{
      type: HostListener,
      args: ['keydown.space', ['$event']]
    }]
  });
})();
const DIRECTIVES = [UsableDirective];
class UsableModule {
  static {
    this.ɵfac = function UsableModule_Factory(t) {
      return new (t || UsableModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: UsableModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UsableModule, [{
    type: NgModule,
    args: [{
      imports: DIRECTIVES,
      exports: DIRECTIVES
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, UsableDirective, UsableModule };
