import { Pipe, PipeTransform } from "@angular/core";
import { Application } from "../models";
import { NgStore } from "@sfpd/ng-store";
import { AppState } from "@app/store";

@Pipe({
  name: 'application',
  standalone: true
})
export class ApplicationPipe implements PipeTransform {

  constructor(private _store: NgStore<AppState>) { }

  /**
   * 
   * @param value
   */
  public transform(value: number) {
    const app: Application | null = this._store.findValueByKey(s => s.applications, value);

    if (app !== null) {
      return app.name;
    }

    return `Application #${value} not found`;
  }

}
