<ngs-container [query$]="query$"
               [data$]="data$"
               (changed)="setForm($event)">
  <ng-template>
    <form ng-ui-form
          [formGroup]="form"
          (ngSubmit)="upsert()">
      <div ng-ui-form-control="Agent">
        <noti-user-picker formControlName="agent" />
      </div>
      <div ng-ui-form-controls>
        <div ng-ui-form-control="Created by">
          <noti-application-picker formControlName="createdBy" />
        </div>
        <div ng-ui-form-control="Audience">
          <noti-application-picker formControlName="audience"
                                   [multiple]="true" />
        </div>
      </div>
      <div ng-ui-form-control="Data">
        <textarea formControlName="data"></textarea>
      </div>
      <div ng-ui-button-group
           alignment="center">
        <button ng-ui-button
                color="success"
                icon="times"
                size="medium"
                [disabled]="form.valid === false"
                [loading]="(processing$ | async) === true">
          Confirm
        </button>
        <button ng-ui-button
                typ="submit"
                color="error"
                icon="times"
                size="medium"
                (click)="dialog.close()">
          Cancel
        </button>
      </div>
    </form>
  </ng-template>
</ngs-container>
