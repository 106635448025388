<ng-container *ngIf="globalService.state$ | async as state">

  <ng-container *ngIf="state === 'loading'">
    <div class="flex-1 flex-column flex-aic flex-jcc">
      <ng-ui-loader animation="triple-spinner" size="20rem" />
    </div>
  </ng-container>

  <ng-container *ngIf="state === 'loaded'">
    <ng-ui-toaster />
    <noti-toolbar />
    <router-outlet />
  </ng-container>

  <div class="flex-1 flex-row flex-aic flex-jcc" *ngIf="state === 'forbidden'">
    <ng-ui-message color="error" label="Access denied" icon="lock-keyhole">
      You don't have access to this application
    </ng-ui-message>
  </div>
</ng-container>
