import * as i0 from '@angular/core';
import { ElementRef, inject, NgZone, Directive, Input, HostListener, NgModule } from '@angular/core';
import { NgControl } from '@angular/forms';
import 'inputmask';
const createMask = options => typeof options === 'string' ? {
  mask: options
} : options;

// TODO: Guidelines
class InputMaskDirective {
  constructor() {
    /********************************************************************** SERVICES **********************************************************************/
    this._elementRef = inject(ElementRef);
    this._zone = inject(NgZone);
    this._ngControl = inject(NgControl, {
      optional: true,
      self: true
    });
    /********************************************************************** BINDINGS **********************************************************************/
    this.options = null;
    this._validator = this.validate.bind(this);
  }
  onChange(input) {
    if (this._ngControl?.control) {
      this._ngControl.control.setValue(input.value);
      this._ngControl.control.updateValueAndValidity();
    }
  }
  /********************************************************************** LIFE CYCLE **********************************************************************/
  ngOnInit() {
    if (this.options !== null) {
      this.instance = this._zone.runOutsideAngular(() => new Inputmask(this.options).mask(this._elementRef.nativeElement));
    }
    if (this._ngControl?.control) {
      this._ngControl.control.addValidators(this._validator);
      this._ngControl.control.updateValueAndValidity();
    }
  }
  ngOnDestroy() {
    this._ngControl?.control?.removeValidators(this._validator);
    this.instance?.remove();
  }
  /********************************************************************** VALIDATOR **********************************************************************/
  /**
   *
   * @param control
   */
  validate(control) {
    return !control.value || this.instance?.isValid() === true ? null : {
      inputMask: true
    };
  }
  static {
    this.ɵfac = function InputMaskDirective_Factory(t) {
      return new (t || InputMaskDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: InputMaskDirective,
      selectors: [["", "inputMask", ""]],
      hostBindings: function InputMaskDirective_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("change", function InputMaskDirective_change_HostBindingHandler($event) {
            return ctx.onChange($event.target);
          });
        }
      },
      inputs: {
        options: [0, "inputMask", "options"]
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputMaskDirective, [{
    type: Directive,
    args: [{
      selector: '[inputMask]',
      standalone: true
    }]
  }], null, {
    options: [{
      type: Input,
      args: ['inputMask']
    }],
    onChange: [{
      type: HostListener,
      args: ['change', ['$event.target']]
    }]
  });
})();
const DIRECTIVES = [InputMaskDirective];
class InputMaskModule {
  static {
    this.ɵfac = function InputMaskModule_Factory(t) {
      return new (t || InputMaskModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: InputMaskModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InputMaskModule, [{
    type: NgModule,
    args: [{
      imports: DIRECTIVES,
      exports: DIRECTIVES
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, InputMaskDirective, InputMaskModule, createMask };
