import { Pipe, PipeTransform } from "@angular/core";
import { User } from "@app/models";
import { NgStore } from "@sfpd/ng-store";
import { AppState } from "@app/store";

@Pipe({
  name: 'user',
  standalone: true
})
export class UserPipe implements PipeTransform {

  constructor(private _store: NgStore<AppState>) { }

  /**
   * 
   * @param value
   */
  public transform(value: number) {
    const user: User | null = this._store.findValueByKey(s => s.users, value);

    if (user !== null) {
      return `${user.firstName} ${user.lastName.toUpperCase()}`;
    }

    return `User #${value} not found`;
  }

}
