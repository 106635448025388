<h1 class="flex-row flex-aic gap1">
  <ng-ui-icon name="bell"
              brush="regular"></ng-ui-icon>
  Notifications
</h1>

<div class="box mt2 flex-row flex-aic flex-jcc">
  <ngs-container [query$]="query$"
                 [data$]="data$">
    <ng-template let-notifications>
      <div ng-ui-button-group
           alignment="end">
        <button ng-ui-button
                class="mb2"
                color="primary"
                icon="plus"
                (click)="showUpsertNotificationDialog()">New notification</button>
      </div>

      <div class="flex-row flex-aic gap1">
        <div class="flex-1 flex-row flex-aic gap1">
          <div>From</div>
          <ng-ui-date-picker [(ngModel)]="from" />
          <div>To</div>
          <ng-ui-date-picker [(ngModel)]="to" />
          <button ng-ui-icon
                  class="refresh"
                  color="primary"
                  name="arrows-rotate"
                  size="2rem"
                  [disabled]="autoRefresh === true"
                  (click)="setObservables()"></button>
        </div>
        <div>
          <ng-ui-checkbox [(ngModel)]="autoRefresh"
                          (change)="setObservables()">
            Auto-refresh
          </ng-ui-checkbox>
        </div>
      </div>
      <ng-ui-table class="mt2"
                   [source]="notifications"
                   saveState="true"
                   displayTopPagination="false">
        <tr *ngUiTableHeaderRow>
          <th scope="col"
              ngUiTableHeader="userId"
              sortable="true"
              filterable="true"
              [columnValueComparer]="userValueComparer"
              [columnFilterExtractor]="userFilterExtractor">Agent</th>
          <th scope="col"
              ngUiTableHeader="createdAt"
              sortable="true"
              sorted="true"
              sortDirection="descending">Created at</th>
          <th scope="col"
              ngUiTableHeader="createdBy"
              sortable="true"
              filterable="true"
              [columnValueComparer]="appValueComparer"
              [columnFilterExtractor]="appFilterExtractor">Created by</th>
          <th scope="col"
              ngUiTableHeader="readAt"
              class="statut">Statut</th>
          <th scope="col"
              class="icon"></th>
        </tr>
        <ng-container *ngUiTableBodyRow="let notification">
          <tr class="pointer"
              (click)="details.toggle()">
            <td>{{ notification.userId | user }}</td>
            <td>{{ notification.createdAt | date: 'medium' }}</td>
            <td>{{ notification.createdById | application }}</td>
            <td>
              <ng-ui-icon name="times"
                          color="error"
                          tooltip="Not yet read"
                          tooltipColor="error"
                          *ngIf="notification.readAt === null"></ng-ui-icon>
              <ng-ui-icon name="check"
                          color="success"
                          tooltipColor="success"
                          [tooltip]="'Read at ' + (notification.readAt | date: 'medium')"
                          *ngIf="notification.readAt !== null"></ng-ui-icon>
            </td>
            <td>
              <ng-ui-menu-trigger icon="ellipsis-v"
                                  [menu]="menu"
                                  (beforeOpen)="setCurrent(notification.id)"></ng-ui-menu-trigger>
            </td>
          </tr>
          <tr #details="togglable"
              togglable>
            <td colspan="5"
                *ngIf="details.visible === true">
              <noti-notification [id]="notification.id"></noti-notification>
            </td>
          </tr>
        </ng-container>
        <tr *ngUiTableBodyEmptyRow>
          <td colspan="5"
              class="text-center">No data</td>
        </tr>
      </ng-ui-table>
    </ng-template>
  </ngs-container>
</div>

<ng-ui-menu #menu>
  <ng-container *ngIf="current$ | async as current">
    <ng-ui-menu-item icon="copy"
                     iconColor="var(--ng-ui-primary)"
                     (itemClick)="openDuplicateDialog(current.value.id)">Duplicate</ng-ui-menu-item>
    <ng-ui-menu-item icon="trash-alt"
                     iconColor="var(--ng-ui-error)"
                     (itemClick)="deleteDialog.open()">Delete</ng-ui-menu-item>
  </ng-container>
</ng-ui-menu>

<ng-ui-dialog #deleteDialog
              label="Delete a notification"
              icon="trash-alt"
              color="error">
  <ng-container *ngUiDialogContent>
    <ng-container *ngIf="current$ | async as current">
      <div>Do you really want to delete this notification?</div>
      <div ng-ui-button-group
           alignment="center"
           class="mt2">
        <button ng-ui-button
                color="success"
                size="normal"
                icon="check"
                [loading]="current.deleting === true"
                (click)="deleteNotification(current.value.id)">Yes</button>
        <button ng-ui-button
                color="error"
                size="normal"
                icon="times"
                (click)="deleteDialog.close()">No</button>
      </div>
    </ng-container>
  </ng-container>
</ng-ui-dialog>

<ng-ui-dialog #upsertNotificationDialog
              label="Upsert a notification"
              icon="bell"
              color="primary">
  <ng-container *ngUiDialogContent>
    <noti-notification-upsert-form [sourceId]="duplicateId" />
  </ng-container>
</ng-ui-dialog>
