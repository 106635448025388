import { Inject, Injectable, Injector, NgZone, Optional } from '@angular/core';
import * as signalR from "@microsoft/signalr";
import { OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, Observable } from 'rxjs';
import { HUB_SERVICES } from '../tokens';
import { DateService } from './date.service';
import { getServicesForMethod } from '@app/decorators';

type HubMessage = {
  method: string;
  parameters: any[]
}

export enum ConnectionState {
  Connecting,
  Connected,
  Reconnecting
}

@Injectable({
  providedIn: 'root',
})
export class HubService {

  /******************************************************** VARIABLES ********************************************************/

  private _state: BehaviorSubject<ConnectionState> = new BehaviorSubject<ConnectionState>(ConnectionState.Connecting);
  private _connection: signalR.HubConnection | null = null;
  private _options: signalR.IHttpConnectionOptions = {
    skipNegotiation: true,
    transport: 1,
    accessTokenFactory: () => this._oauthService.getAccessToken()
  };

  /******************************************************** ACCESSORS ********************************************************/

  public get state$(): Observable<ConnectionState> {
    return this._state.asObservable();
  }

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(
    private _oauthService: OAuthService,
    private _dateService: DateService,
    private _zone: NgZone,
    private _injector: Injector,
    @Optional() @Inject(HUB_SERVICES) private _services: any[]
  ) { }

  /******************************************************** PUBLIC ********************************************************/

  /** */
  public ensureConnection(): Observable<boolean> {
    return new Observable(observer => {
      if (!this._connection) {
        this._connection = new signalR.HubConnectionBuilder()
          .withUrl('/hub/private', this._options)
          .withAutomaticReconnect({
            nextRetryDelayInMilliseconds: () => 5000
          })
          .build();

        this._connection.on("message", (message: HubMessage) => this._handleMessage(message));

        this._connection.onreconnecting(() => this._state.next(ConnectionState.Reconnecting));
        this._connection.onreconnected(() => location.reload());
      }

      if (this._connection.state == signalR.HubConnectionState.Disconnected) {
        this._connection
          .start()
          .then(() => {
            observer.next(true);
            observer.complete();

            this._state.next(ConnectionState.Connected);
          }, () => {
            observer.next(false);
            observer.complete();
          });
      }
    });
  }

  /******************************************************** PRIVATE ********************************************************/

  /** */
  private _handleMessage(message: HubMessage): void {
    for (let parameter of message.parameters) {
      this._dateService.convertToDate(parameter);
    }

    const methodName = `on${message.method}`;
    const services = getServicesForMethod(methodName);

    for (const name of services) {
      const service = this._injector.get(name);

      this._zone.run(() => service[methodName].call(service, ...message.parameters));
    }
  }
}
