import { DecimalPipe } from '@angular/common';
import * as i0 from '@angular/core';
import { ChangeDetectorRef, inject, EventEmitter, Component, ViewEncapsulation, ChangeDetectionStrategy, Input, Output, NgModule } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseFormControl } from '@sfpd/ng-ui/core';
import { TranslatePipe } from '@sfpd/ng-ui/core/internal';
import { NG_UI_ICON_OPTIONS, IconComponent } from '@sfpd/ng-ui/icon';
import { timer } from 'rxjs';

// TODO: Guidelines
class NumberComponent extends BaseFormControl {
  constructor() {
    super(...arguments);
    /******************************************************** SERVICES ********************************************************/
    this._cdr = inject(ChangeDetectorRef);
    this._iconOptions = inject(NG_UI_ICON_OPTIONS, {
      optional: true
    });
    /******************************************************** BINDINGS ********************************************************/
    this.orientation = 'horizontal';
    this.iconBrush = this._iconOptions?.defaultNumberBrush || 'regular';
    this.color = 'primary';
    this.change = new EventEmitter();
    /******************************************************** VARIABLES ********************************************************/
    this._value = 0;
    this._incrementSubscription = null;
    this._max = 100;
    this._min = 0;
    this._step = 1;
  }
  get min() {
    return this._min;
  }
  set min(value) {
    this._min = Number(value) || 0;
    this._ensureValueBetweenLimits();
  }
  get max() {
    return this._max;
  }
  set max(value) {
    this._max = Number(value) || 100;
    this._ensureValueBetweenLimits();
  }
  get step() {
    return this._step;
  }
  set step(value) {
    this._step = Number(value) || 1;
  }
  get value() {
    return this._value;
  }
  set value(v) {
    this.writeValue(v);
  }
  /******************************************************** CONTROL VALUE ACCESSOR ********************************************************/
  /** */
  writeValue(obj) {
    this._value = Number(obj) || 0;
    this._ensureValueBetweenLimits();
    this._cdr.markForCheck();
  }
  /******************************************************** INTERNAL ********************************************************/
  /**
   *
   * @param event
   */
  _end(event) {
    this._increment(this.max - this.value, event);
  }
  /**
   *
   * @param event
   */
  _home(event) {
    this._increment(-this.value, event);
  }
  /**
   *
   * @param increment
   */
  _increment(increment, event = null) {
    if (this._canIncrement(increment) && this._incrementSubscription === null) {
      this._value += increment;
      this._onChange && this._onChange(this._value);
      this.change.emit(this._value);
    }
    event?.preventDefault();
  }
  /**
   *
   * @param increment
   */
  _startIncrement(increment) {
    if (this._canIncrement(increment)) {
      this._value += increment;
      this._onChange && this._onChange(this._value);
      this.change.emit(this._value);
      this._incrementSubscription = timer(500, 50).subscribe(() => {
        if (this._canIncrement(increment)) {
          this._value += increment;
          this._onChange && this._onChange(this._value);
          this.change.emit(this._value);
          this._cdr.markForCheck();
        }
      });
    }
  }
  /** */
  _stopIncrement() {
    this._incrementSubscription?.unsubscribe();
    this._incrementSubscription = null;
  }
  /******************************************************** PRIVATE ********************************************************/
  /**
   *
   * @param increment
   */
  _canIncrement(increment) {
    return this.disabled === false && (increment > 0 && this._value + this.step <= this.max || increment < 0 && this._value - this.step >= this.min);
  }
  /** */
  _ensureValueBetweenLimits() {
    if (this._value < this.min) {
      this._value = this.min;
      console.warn('The value assigned to the ng-ui-number is lower than the minimum allowed');
    }
    if (this._value > this.max) {
      this._value = this.max;
      console.warn('The value assigned to the ng-ui-number is greater than the maximum allowed');
    }
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵNumberComponent_BaseFactory;
      return function NumberComponent_Factory(t) {
        return (ɵNumberComponent_BaseFactory || (ɵNumberComponent_BaseFactory = i0.ɵɵgetInheritedFactory(NumberComponent)))(t || NumberComponent);
      };
    })();
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NumberComponent,
      selectors: [["ng-ui-number"]],
      hostAttrs: ["role", "spinbutton"],
      hostVars: 7,
      hostBindings: function NumberComponent_HostBindings(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵlistener("keydown.arrowdown", function NumberComponent_keydown_arrowdown_HostBindingHandler($event) {
            return ctx._increment(-ctx.step, $event);
          })("keydown.arrowup", function NumberComponent_keydown_arrowup_HostBindingHandler($event) {
            return ctx._increment(ctx.step, $event);
          })("keydown.home", function NumberComponent_keydown_home_HostBindingHandler($event) {
            return ctx._home($event);
          })("keydown.end", function NumberComponent_keydown_end_HostBindingHandler($event) {
            return ctx._end($event);
          })("keydown.pagedown", function NumberComponent_keydown_pagedown_HostBindingHandler($event) {
            return ctx._increment(-ctx.step * 10, $event);
          })("keydown.pageup", function NumberComponent_keydown_pageup_HostBindingHandler($event) {
            return ctx._increment(ctx.step * 10, $event);
          })("wheel", function NumberComponent_wheel_HostBindingHandler($event) {
            return $event.deltaY > 0 ? ctx._increment(-ctx.step, $event) : ctx._increment(ctx.step, $event);
          });
        }
        if (rf & 2) {
          i0.ɵɵattribute("aria-disabled", ctx.disabled)("aria-valuenow", ctx._value)("aria-valuemin", ctx.min)("aria-valuemax", ctx.max)("tabIndex", ctx.disabled === true ? null : 0);
          i0.ɵɵclassMap("ng-ui-number-" + ctx.orientation);
        }
      },
      inputs: {
        orientation: "orientation",
        iconBrush: "iconBrush",
        min: "min",
        max: "max",
        step: "step",
        color: "color",
        value: "value"
      },
      outputs: {
        change: "change"
      },
      standalone: true,
      features: [i0.ɵɵProvidersFeature([{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumberComponent,
        multi: true
      }]), i0.ɵɵInheritDefinitionFeature, i0.ɵɵStandaloneFeature],
      decls: 7,
      vars: 17,
      consts: [["ng-ui-icon", "", "tabindex", "-1", "type", "button", 3, "mousedown", "mouseup", "mouseleave", "name", "brush", "color", "disabled"], [1, "ng-ui-number-value"]],
      template: function NumberComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "button", 0);
          i0.ɵɵpipe(1, "translate");
          i0.ɵɵlistener("mousedown", function NumberComponent_Template_button_mousedown_0_listener() {
            return ctx._startIncrement(ctx.orientation === "horizontal" ? -ctx.step : ctx.step);
          })("mouseup", function NumberComponent_Template_button_mouseup_0_listener() {
            return ctx._stopIncrement();
          })("mouseleave", function NumberComponent_Template_button_mouseleave_0_listener() {
            return ctx._stopIncrement();
          });
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵtext(3);
          i0.ɵɵpipe(4, "number");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "button", 0);
          i0.ɵɵpipe(6, "translate");
          i0.ɵɵlistener("mousedown", function NumberComponent_Template_button_mousedown_5_listener() {
            return ctx._startIncrement(ctx.orientation === "horizontal" ? ctx.step : -ctx.step);
          })("mouseup", function NumberComponent_Template_button_mouseup_5_listener() {
            return ctx._stopIncrement();
          })("mouseleave", function NumberComponent_Template_button_mouseleave_5_listener() {
            return ctx._stopIncrement();
          });
          i0.ɵɵelementEnd();
        }
        if (rf & 2) {
          i0.ɵɵproperty("name", ctx.orientation === "horizontal" ? "minus" : "chevron-up")("brush", ctx.iconBrush)("color", ctx.color)("disabled", ctx.disabled);
          i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(1, 11, ctx.orientation === "horizontal" ? "ng-ui-number-decrement" : "ng-ui-number-increment"));
          i0.ɵɵadvance(3);
          i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(4, 13, ctx.value));
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("name", ctx.orientation === "horizontal" ? "plus" : "chevron-down")("brush", ctx.iconBrush)("color", ctx.color)("disabled", ctx.disabled);
          i0.ɵɵattribute("aria-label", i0.ɵɵpipeBind1(6, 15, ctx.orientation === "horizontal" ? "ng-ui-number-decrement" : "ng-ui-number-increment"));
        }
      },
      dependencies: [DecimalPipe, IconComponent, TranslatePipe],
      styles: ["ng-ui-number{width:-moz-fit-content;width:fit-content;display:inline-flex;align-items:center;gap:calc(1rem * var(--ng-ui-gap-factor))}ng-ui-number.ng-ui-number-horizontal{flex-direction:row}ng-ui-number.ng-ui-number-vertical{flex-direction:column}ng-ui-number:focus,ng-ui-number:focus-within{outline:none}ng-ui-number:focus .ng-ui-number-value,ng-ui-number:focus-within .ng-ui-number-value{font-weight:700}ng-ui-number.ng-ui-form-normal-validation.ng-touched.ng-invalid,ng-ui-number.ng-ui-form-extended-validation.ng-touched.ng-invalid{color:var(--ng-ui-error)}ng-ui-number.ng-ui-form-extended-validation.ng-touched.ng-valid{color:var(--ng-ui-success)}\n"],
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberComponent, [{
    type: Component,
    args: [{
      selector: 'ng-ui-number',
      standalone: true,
      imports: [DecimalPipe, IconComponent, TranslatePipe],
      encapsulation: ViewEncapsulation.None,
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: NumberComponent,
        multi: true
      }],
      host: {
        'role': 'spinbutton',
        '[class]': '"ng-ui-number-" + orientation',
        '[attr.aria-disabled]': 'disabled',
        '[attr.aria-valuenow]': '_value',
        '[attr.aria-valuemin]': 'min',
        '[attr.aria-valuemax]': 'max',
        '[attr.tabIndex]': 'disabled === true ? null : 0',
        '(keydown.arrowdown)': '_increment(-step, $event)',
        '(keydown.arrowup)': '_increment(step, $event)',
        '(keydown.home)': '_home($event)',
        '(keydown.end)': '_end($event)',
        '(keydown.pagedown)': '_increment(-step * 10, $event)',
        '(keydown.pageup)': '_increment(step * 10, $event)',
        '(wheel)': '$event.deltaY > 0 ? _increment(-step, $event) : _increment(step, $event)'
      },
      template: "<button ng-ui-icon\r\n        tabindex=\"-1\"\r\n        type=\"button\"\r\n        [attr.aria-label]=\"(orientation === 'horizontal' ? 'ng-ui-number-decrement' : 'ng-ui-number-increment') | translate\"\r\n        [name]=\"orientation === 'horizontal' ? 'minus' : 'chevron-up'\"\r\n        [brush]=\"iconBrush\"\r\n        [color]=\"color\"\r\n        [disabled]=\"disabled\"\r\n        (mousedown)=\"_startIncrement(orientation === 'horizontal' ? -step : step)\"\r\n        (mouseup)=\"_stopIncrement()\"\r\n        (mouseleave)=\"_stopIncrement()\"></button>\r\n<div class=\"ng-ui-number-value\">{{ value | number }}</div>\r\n<button ng-ui-icon\r\n        tabindex=\"-1\"\r\n        type=\"button\"\r\n        [attr.aria-label]=\"(orientation === 'horizontal' ? 'ng-ui-number-decrement' : 'ng-ui-number-increment') | translate\"\r\n        [name]=\"orientation === 'horizontal' ? 'plus' : 'chevron-down'\"\r\n        [brush]=\"iconBrush\"\r\n        [color]=\"color\"\r\n        [disabled]=\"disabled\"\r\n        (mousedown)=\"_startIncrement(orientation === 'horizontal' ? step : -step)\"\r\n        (mouseup)=\"_stopIncrement()\"\r\n        (mouseleave)=\"_stopIncrement()\"></button>\r\n",
      styles: ["ng-ui-number{width:-moz-fit-content;width:fit-content;display:inline-flex;align-items:center;gap:calc(1rem * var(--ng-ui-gap-factor))}ng-ui-number.ng-ui-number-horizontal{flex-direction:row}ng-ui-number.ng-ui-number-vertical{flex-direction:column}ng-ui-number:focus,ng-ui-number:focus-within{outline:none}ng-ui-number:focus .ng-ui-number-value,ng-ui-number:focus-within .ng-ui-number-value{font-weight:700}ng-ui-number.ng-ui-form-normal-validation.ng-touched.ng-invalid,ng-ui-number.ng-ui-form-extended-validation.ng-touched.ng-invalid{color:var(--ng-ui-error)}ng-ui-number.ng-ui-form-extended-validation.ng-touched.ng-valid{color:var(--ng-ui-success)}\n"]
    }]
  }], null, {
    orientation: [{
      type: Input
    }],
    iconBrush: [{
      type: Input
    }],
    min: [{
      type: Input
    }],
    max: [{
      type: Input
    }],
    step: [{
      type: Input
    }],
    color: [{
      type: Input
    }],
    value: [{
      type: Input
    }],
    change: [{
      type: Output
    }]
  });
})();
const COMPONENTS = [NumberComponent];
class NumberModule {
  static {
    this.ɵfac = function NumberModule_Factory(t) {
      return new (t || NumberModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NumberModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      imports: [COMPONENTS]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumberModule, [{
    type: NgModule,
    args: [{
      imports: COMPONENTS,
      exports: COMPONENTS
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { COMPONENTS, NumberComponent, NumberModule };
