import * as i0 from '@angular/core';
import { inject, Pipe } from '@angular/core';
import { NG_UI_TRANSLATOR } from '@sfpd/ng-ui/core';
class TranslatePipe {
  constructor() {
    /****************************************************************** SERVICES ******************************************************************/
    this._translator = inject(NG_UI_TRANSLATOR, {
      optional: true
    });
  }
  /****************************************************************** BINDINGS ******************************************************************/
  /** */
  transform(value, ...args) {
    if (!this._translator) {
      return value;
    }
    return this._translator.translate(value, args);
  }
  static {
    this.ɵfac = function TranslatePipe_Factory(t) {
      return new (t || TranslatePipe)();
    };
  }
  static {
    this.ɵpipe = /* @__PURE__ */i0.ɵɵdefinePipe({
      name: "translate",
      type: TranslatePipe,
      pure: true,
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TranslatePipe, [{
    type: Pipe,
    args: [{
      name: 'translate',
      standalone: true
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TranslatePipe };
