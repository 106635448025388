import * as i0 from '@angular/core';
import { InjectionToken, inject, Injectable, Inject, Directive, Input, ViewContainerRef, Component, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef, EventEmitter, TemplateRef, ContentChild, Output, makeEnvironmentProviders, NgModule } from '@angular/core';
import { filterArray, mapArray, mapToError } from '@sfpd/rxjs-plus';
import { enableMapSet, produce } from 'immer';
import { BehaviorSubject, map, filter, take, distinctUntilChanged, throwError, finalize, tap, catchError, of, share, Observable, shareReplay, switchMap, delay } from 'rxjs';
import { NgForOf, AsyncPipe, JsonPipe, NgIf, NgTemplateOutlet } from '@angular/common';
const _c0 = ["host"];
const _c1 = a0 => ({
  $implicit: a0
});
function NgStoreContainerComponent_ng_template_0_ng_template_0_ng_container_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function NgStoreContainerComponent_ng_template_0_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, NgStoreContainerComponent_ng_template_0_ng_template_0_ng_container_0_Template, 1, 0, "ng-container", 5);
    i0.ɵɵpipe(1, "async");
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.template)("ngTemplateOutletContext", i0.ɵɵpureFunction1(4, _c1, !!ctx_r0.data$ ? i0.ɵɵpipeBind1(1, 2, ctx_r0.data$) : null));
  }
}
function NgStoreContainerComponent_ng_template_0_ng_container_2_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 7);
    i0.ɵɵelement(1, "ngs-loader-host", 8);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("size", ctx_r0.loaderSize);
  }
}
function NgStoreContainerComponent_ng_template_0_ng_container_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtext(1);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(ctx_r0.loaderText);
  }
}
function NgStoreContainerComponent_ng_template_0_ng_container_2_ng_container_3_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function NgStoreContainerComponent_ng_template_0_ng_container_2_ng_container_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NgStoreContainerComponent_ng_template_0_ng_container_2_ng_container_3_ng_container_1_Template, 1, 0, "ng-container", 9);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.loaderTemplate);
  }
}
function NgStoreContainerComponent_ng_template_0_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NgStoreContainerComponent_ng_template_0_ng_container_2_div_1_Template, 2, 1, "div", 6)(2, NgStoreContainerComponent_ng_template_0_ng_container_2_ng_container_2_Template, 2, 1, "ng-container", 3)(3, NgStoreContainerComponent_ng_template_0_ng_container_2_ng_container_3_Template, 2, 1, "ng-container", 3);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.loaderType === "component");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.loaderType === "text");
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.loaderType === "template");
  }
}
function NgStoreContainerComponent_ng_template_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, NgStoreContainerComponent_ng_template_0_ng_template_0_Template, 2, 6, "ng-template", null, 2, i0.ɵɵtemplateRefExtractor)(2, NgStoreContainerComponent_ng_template_0_ng_container_2_Template, 4, 3, "ng-container", 4);
    i0.ɵɵpipe(3, "async");
  }
  if (rf & 2) {
    const dataTpl_r2 = i0.ɵɵreference(1);
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(2);
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(3, 2, ctx_r0.loaded$) !== true)("ngIfElse", dataTpl_r2);
  }
}
function NgStoreContainerComponent_ng_template_2_pre_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "pre");
    i0.ɵɵtext(1);
    i0.ɵɵpipe(2, "json");
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const error_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵadvance();
    i0.ɵɵtextInterpolate(i0.ɵɵpipeBind1(2, 1, error_r3));
  }
}
function NgStoreContainerComponent_ng_template_2_ngs_error_host_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "ngs-error-host", 11);
  }
  if (rf & 2) {
    const error_r3 = i0.ɵɵnextContext().$implicit;
    i0.ɵɵproperty("error", error_r3);
  }
}
function NgStoreContainerComponent_ng_template_2_ng_container_2_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function NgStoreContainerComponent_ng_template_2_ng_container_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NgStoreContainerComponent_ng_template_2_ng_container_2_ng_container_1_Template, 1, 0, "ng-container", 5);
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const error_r3 = i0.ɵɵnextContext().$implicit;
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngTemplateOutlet", ctx_r0.errorTemplate)("ngTemplateOutletContext", i0.ɵɵpureFunction1(2, _c1, error_r3));
  }
}
function NgStoreContainerComponent_ng_template_2_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵtemplate(0, NgStoreContainerComponent_ng_template_2_pre_0_Template, 3, 3, "pre", 3)(1, NgStoreContainerComponent_ng_template_2_ngs_error_host_1_Template, 1, 1, "ngs-error-host", 10)(2, NgStoreContainerComponent_ng_template_2_ng_container_2_Template, 2, 4, "ng-container", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("ngIf", !ctx_r0.config.errorComponent && ctx_r0.errorTemplate === null);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.config.errorComponent && ctx_r0.errorTemplate === null);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", ctx_r0.errorTemplate !== null);
  }
}
function NgStoreContainerComponent_ng_container_4_ng_container_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainer(0);
  }
}
function NgStoreContainerComponent_ng_container_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementContainerStart(0);
    i0.ɵɵtemplate(1, NgStoreContainerComponent_ng_container_4_ng_container_1_Template, 1, 0, "ng-container", 12);
    i0.ɵɵpipe(2, "async");
    i0.ɵɵelementContainerEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    const contentTpl_r4 = i0.ɵɵreference(1);
    const errorTpl_r5 = i0.ɵɵreference(3);
    i0.ɵɵadvance();
    i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 3, ctx_r0.query$))("ngIfThen", errorTpl_r5)("ngIfElse", contentTpl_r4);
  }
}
const NG_STORE_CONFIG = new InjectionToken('NG_STORE_CONFIG');
let nextUniqueId = 0;
const createEntity = value => {
  return {
    uid: nextUniqueId++,
    loaded: !!value,
    loading: false,
    value: value,
    busy: false,
    deleting: false,
    updating: false
  };
};
const createEntities = (values = [], indices = []) => {
  const entities = {
    uid: nextUniqueId++,
    _array: values.map(value => createEntity(value)),
    _indices: {},
    _entities: new Map(),
    _indiceNames: new Set(indices),
    busy: false,
    loaded: false,
    adding: false,
    loading: false
  };
  for (const index of indices) {
    entities._indices[index] = new Map();
  }
  for (let i = 0, length = entities._array.length; i < length; ++i) {
    const entity = entities._array[i];
    entities._entities.set(entity.value.id, i);
    for (const index of indices) {
      const value = entity.value[index];
      const map = entities._indices[index];
      if (map.has(value)) {
        map.get(value).push(i);
      } else {
        map.set(value, [i]);
      }
    }
  }
  return entities;
};
const findStoreValueByKey = (root, key) => {
  return s => {
    const position = root(s)._entities.get(key);
    return position === undefined ? null : root(s)._array[position]?.value || null;
  };
};
function _isUndefined(value) {
  return typeof value === 'undefined';
}
class NgStore {
  /********************************************************************** ACCESSORS **********************************************************************/
  get value() {
    return this._store.value;
  }
  get root() {
    return this._root;
  }
  /****************************************************************** LIFE CYCLE ******************************************************************/
  constructor(config) {
    /****************************************************************** VARIABLES ******************************************************************/
    this._addingStates = new Map();
    this._deletingStates = new Map();
    this._loadingStates = new Map();
    this._updatingStates = new Map();
    this._executingQueries = new Map();
    this._executedQueries = new Set();
    this._executedQueriesSubject = new BehaviorSubject(this._executedQueries);
    this.executedQueries$ = this._executedQueriesSubject.asObservable();
    enableMapSet();
    this._http = inject(config.httpClientType);
    this._store = new BehaviorSubject(config.initialValue);
    this._root = this._store.asObservable();
  }
  /********************************************************************** PUBLIC **********************************************************************/
  /**
   * Clear the whole store or just a section
   *
   * @param selector  Selector to returns the part of the store to clear
   */
  clear(selector) {
    this.removeValuesBy(selector, () => true);
  }
  /**
   * Returns the first entity based on a predicate
   *
   * @param selector  Selector to returns the part of the store to filter
   * @param predicate Predicate to select the entity
   * @param store     By default the search is performed on the store, but you can pass another version of the store in this parameter
   */
  findEntityBy(selector, predicate, store = this.value) {
    return selector(store)._array.find(e => _isUndefined(e) === false && predicate(e)) || null;
  }
  /**
   * Returns the first entity with a specific key
   *
   * @param selector  Selector to returns the part of the store to filter
   * @param key       Key used for the entity selection
   * @param store     By default the search is performed on the store, but you can pass another version of the store in this parameter
   */
  findEntityByKey(selector, key, store = this.value) {
    const root = selector(store);
    const position = root._entities.get(key);
    return position === undefined ? null : root._array[position] || null;
  }
  /**
   * Returns the first entity with a unique id
   *
   * @param selector  Selector to returns the part of the store to filter
   * @param uid       Unique ID used for the entity selection
   * @param store     By default the search is performed on the store, but you can pass another version of the store in this parameter
   */
  findEntityByUniqueId(selector, uid, store = this.value) {
    const root = selector(store);
    return root._array.find(e => e.uid === uid) || null;
  }
  /**
  * Returns all entities matching a predicate
  *
  * @param selector   Selector to returns the part of the store to filter
  * @param predicate  Predicate to select the the entities
   * @param store     By default the search is performed on the store, but you can pass another version of the store in this parameter
  */
  findEntitiesBy(selector, predicate, store = this.value) {
    return selector(store)._array.filter(e => _isUndefined(e) === false && predicate(e));
  }
  /**
   * Returns the first value based on a predicate
   *
   * @param selector  Selector to returns the part of the store to filter
   * @param predicate Predicate to select the value
   * @param store     By default the search is performed on the store, but you can pass another version of the store in this parameter
   */
  findValueBy(selector, predicate, store = this.value) {
    return selector(store)._array.find(e => _isUndefined(e) === false && predicate(e.value))?.value || null;
  }
  /**
   * Returns the first value based on an index
   *
   * @param selector  Selector to returns the part of the store to filter
   * @param index     Index name
   * @param value     Index value
   * @param store     By default the search is performed on the store, but you can pass another version of the store in this parameter
   */
  findValueByIndex(selector, index, value, store = this.value) {
    const array = selector(store)._indices[index].get(value);
    const position = array && array.length !== 0 ? array[0] : null;
    return position !== null ? selector(store)._array[position].value : null;
  }
  /**
   * Returns the first value with a specific key
   *
   * @param selector  Selector to returns the part of the store to filter
   * @param key       Key used for the entity selection
   * @param store     By default the search is performed on the store, but you can pass another version of the store in this parameter
   */
  findValueByKey(selector, key, store = this.value) {
    return this.findEntityByKey(selector, key, store)?.value || null;
  }
  /**
   * Returns all values matching a predicate
   *
   * @param selector  Selector to returns the part of the store to filter
   * @param predicate Predicate to select the the values
   * @param store     By default the search is performed on the store, but you can pass another version of the store in this parameter
   */
  findValuesBy(selector, predicate, store = this.value) {
    return selector(store)._array.filter(e => _isUndefined(e) === false && predicate(e.value)).map(e => e.value);
  }
  /**
   * Returns values based on an index
   *
   * @param selector  Selector to returns the part of the store to filter
   * @param index     Index name
   * @param value     Index value
   * @param store     By default the search is performed on the store, but you can pass another version of the store in this parameter
   */
  findValuesByIndex(selector, index, value, store = this.value) {
    const array = selector(store)._indices[index].get(value) || [];
    const result = [];
    for (const position of array) {
      result.push(selector(store)._array[position].value);
    }
    return result;
  }
  /**
   * Returns all the entities of a store location
   *
   * @param selector  Function used to locate the entities in the store
   * @param store     By default the search is performed on the store, but you can pass another version of the store in this parameter
   */
  getEntities(selector, store = this.value) {
    return selector(store)._array.filter(e => _isUndefined(e) === false);
  }
  /**
   * Returns all the values of a store location
   *
   * @param selector  Function used to locate the values in the store
   * @param store     By default the search is performed on the store, but you can pass another version of the store in this parameter
   */
  getValues(selector, store = this.value) {
    return this.getEntities(selector, store).map(e => e.value);
  }
  /**
   * Returns whether a specific entity exists in the store
   *
   * @param selector  Location in the store to get the entity from
   * @param key   Key of the entity
   */
  hasEntity(selector, key) {
    return selector(this.value)._entities.has(key);
  }
  /**
   * Returns an observable emitting a value when the entities are loaded then completes
   *
   * @param selector Entity to watch
   *
   * @deprecated This method will disappear in the v3
   */
  isLoaded(selector) {
    return this.select(selector).pipe(map(entity => entity.loaded), filter(loaded => loaded === true), map(() => true), take(1));
  }
  /**
   * Returns an observable emitting a value when a specific query has been correctly executed
   *
   * @param query   Query URL
   */
  isQueryExecuted(query) {
    return this.executedQueries$.pipe(map(queries => queries.has(query)), distinctUntilChanged());
  }
  /**
   * Remove values from the store that match a predicate
   *
   * @param selector  Selector to return the part of the store where values are removed from
   * @param predicate Predicate to match the value
   */
  removeValuesBy(selector, predicate) {
    const ids = this.findValuesBy(selector, predicate).map(v => v.id);
    this.removeEntitiesByKeys(selector, ...ids);
  }
  /**
   * Remove entities from the store by their keys
   *
   * @param selector  Selector to return the part of the store where entities are removed from
   * @param keys      Keys used to remove entities
   */
  removeEntitiesByKeys(selector, ...keys) {
    const root = selector(this.value);
    keys = keys.filter(k => root._entities.has(k));
    if (keys.length !== 0) {
      this.update((draft, state) => {
        const snapshotRoot = selector(state);
        const draftRoot = selector(draft);
        for (let i = snapshotRoot._array.length - 1; i >= 0; --i) {
          if (_isUndefined(snapshotRoot._array[i]) === false && keys.includes(snapshotRoot._array[i].value.id)) {
            draftRoot._entities.delete(snapshotRoot._array[i].value.id);
            for (const index of snapshotRoot._indiceNames) {
              const value = snapshotRoot._array[i].value[index];
              const mapArray = (draftRoot._indices[index].get(value) || []).filter(p => p !== i);
              draftRoot._indices[index].set(value, mapArray);
            }
            delete draftRoot._array[i];
          }
        }
      });
    }
  }
  /**
   * Returns an observable matching a part of the store
   *
   * @param selector Selector to select the part of the store
   */
  select(selector) {
    return this.root.pipe(map(s => selector(s)), distinctUntilChanged());
  }
  /**
   * Returns an observable with all the entities in a part of the store
   *
   * @param selector  Selector to fetch the entities
   */
  selectEntities(selector) {
    return this.select(selector).pipe(map(e => e._array.filter(e => _isUndefined(e) === false)));
  }
  /**
   * Returns an observable filtering the store entities based on a selector
   *
   * @param selector  Selector to fetch the entities
   * @param filter    Function to filter the entities
   */
  selectEntitiesBy(selector, filter) {
    return this.select(selector).pipe(map(e => e._array.filter(e => _isUndefined(e) === false)), filterArray(filter));
  }
  /**
   * Returns an observable filtering the store entities based on a predefined index
   *
   * @param selector  Selector to fetch the entities
   * @param index     Name of the property used to create the index
   * @param value     Index value
   */
  selectEntitiesByIndex(selector, index, value) {
    return this.select(selector).pipe(map(e => (e._indices[index].get(value) || []).map(p => e._array[p])));
  }
  /**
   * Returns an observable with the first entity matching a predicate
   *
   * @param selector  Selector to select the part of the store
   * @param predicate Predicate to find the entity
   */
  selectEntityBy(selector, predicate) {
    return this.selectEntitiesBy(selector, predicate).pipe(map(items => items[0] || null), distinctUntilChanged());
  }
  /**
   * Returns an observable filtering the store entities based on a predefined index. If multiple item correspond to the index, the first one is returned
   *
   * @param selector  Selector to fetch the entities
   * @param index     Name of the property used to create the index
   * @param value     Index value
   */
  selectEntityByIndex(selector, index, value) {
    return this.selectEntitiesByIndex(selector, index, value).pipe(map(entities => entities[0] || null));
  }
  /**
   * Returns an observable with the first entity with the specified key
   *
   * @param selector  Selector to select the part of the store
   * @param key       Key used to find the entity
   */
  selectEntityByKey(selector, key) {
    return this.select(selector).pipe(map(e => {
      const position = e._entities.get(key);
      return position === undefined ? null : e._array[position] || null;
    }), distinctUntilChanged());
  }
  /**
   * Returns an observable with the values of entities
   *
   * @param selector Selector to select the part of the store containing the entities
   */
  selectValues(selector) {
    return this.select(selector).pipe(map(root => root._array), distinctUntilChanged(), filterArray(e => _isUndefined(e) === false, false), mapArray(e => e.value));
  }
  /**
   * Returns an observable filtering the store entities values based on a selector
   *
   * @param selector  Selector to fetch the values
   * @param filter    Function to filter the values
   */
  selectValuesBy(selector, filter) {
    return this.selectValues(selector).pipe(filterArray(filter));
  }
  /**
   * Returns an observable filtering the store entities based on a predefined index
   *
   * @param selector  Selector to fetch the entities
   * @param index     Name of the property used to create the index
   * @param value     Index value
   */
  selectValuesByIndex(selector, index, value) {
    return this.select(selector).pipe(distinctUntilChanged((prev, curr) => prev._array === curr._array), map(root => (root._indices[index].get(value) || []).map(p => root._array[p].value)));
  }
  /**
   * Returns an observable filtering the store entities based on a predefined index. If multiple value are stored at the specified index, only the first one is returned.
   *
   * @param selector  Selector to fetch the entities
   * @param index     Name of the property used to create the index
   * @param value     Index value
   */
  selectValueByIndex(selector, index, value) {
    return this.selectValuesByIndex(selector, index, value).pipe(map(values => values[0] || null));
  }
  /**
   * Returns an observable with the value of an entity
   *
   * @param selector Selector to select the entity
   */
  selectValue(selector) {
    return this.select(selector).pipe(map(e => e.value), distinctUntilChanged());
  }
  /**
   * Returns an observable with the first value matching a predicate
   *
   * @param selector  Selector to select the part of the store containing the entities
   * @param predicate Predicate used to find the value
   */
  selectValueBy(selector, predicate) {
    return this.selectValuesBy(selector, predicate).pipe(map(items => items[0] || null), distinctUntilChanged());
  }
  /**
   * Returns an observable with a value with the specified key
   *
   * @param selector  Selector to select the part of the store containing the entities
   * @param key       Key used to find the value
   */
  selectValueByKey(selector, key) {
    return this.select(selector).pipe(map(e => {
      const position = e._entities.get(key);
      return position === undefined ? null : e._array[position]?.value || null;
    }), distinctUntilChanged());
  }
  /**
   * Update the store
   *
   * @param updater Function used to update the store
   */
  update(updater) {
    // DO NOT REMOVE THE BRACKETS FOR THE SECOND PARAMETER AS BECAUSE OF CURRYING IT WOULD MEAN SOMETHING ELSE
    const nextState = produce(this._store.value, draft => {
      updater(draft, this._store.value);
    });
    this._store.next(nextState);
  }
  /**
  * Update entities based on a predicate
  *
  * @param root      Selector used to fetch the entities containing the value to update
  * @param selector  Selector used to filter entities
  * @param updater   Function used to update entities
  */
  updateEntitiesBy(root, selector, updater) {
    this.update((d, s) => {
      const entities = root(s);
      const ids = entities._array.filter(e => selector(e) === true).map(e => e.value.id);
      for (const id of ids) {
        this._updateEntityByKey(d, s, root, id, updater);
      }
    });
  }
  /**
   * Update an entity by key
   *
   * @param selector  Selector used to fetch the entities containing the entity to update
   * @param key       Key used to fetch the entity to update
   * @param updater   Function used to update the entity
   */
  updateEntityByKey(selector, key, updater) {
    this.update((d, s) => this._updateEntityByKey(d, s, selector, key, entity => updater(entity)));
  }
  /**
   * Update a value by key
   *
   * @param selector  Selector used to fetch the entities containing the value to update
   * @param key       Key used to fetch the value to update
   * @param updater   Function used to update the entity
   */
  updateValueByKey(selector, key, updater) {
    this.update((d, s) => this._updateEntityByKey(d, s, selector, key, entity => updater(entity.value)));
  }
  /**
   * Update values based on a predicate
   *
   * @param root      Selector used to fetch the entities containing the value to update
   * @param selector  Selector used to filter entities
   * @param updater   Function used to update entities
   */
  updateValuesBy(root, selector, updater) {
    this.updateEntitiesBy(root, e => selector(e.value), e => updater(e.value));
  }
  /**
   * Upsert multiple values in the store. If the value already exists in the store, entities are merged.
   *
   * @param selector          Selector defining where the values are added
   * @param values            Values to add
   * @param state             By default, entities are set as "busy: false, loading: false and loaded to true if the value is not null". Can be overriden using this parameter.
   * @param state.busy        Defines whether the entity is currently busy
   * @param state.loading     Defines whether the entity is currently loading
   * @param state.loaded      Defines whether the entity is currently loaded. Could be use to partially loaded entity.
   */
  upsertValues(selector, values, state) {
    this.update(d => this._upsertEntities(selector(d), values, state || {}));
  }
  /**
   * Upsert a value in the store. If the value already exists in the store, entities are merged.
   *
   * @param selector          Selector defining where the value is added
   * @param value             Value to add
   * @param state             By default, the entity is set as "busy: false, loading: false and loaded to true if the value is not null". Can be overriden using this parameter.
   * @param state.busy        Defines whether the entity is currently busy
   * @param state.loading     Defines whether the entity is currently loading
   * @param state.loaded      Defines whether the entity is currently loaded. Could be use to partially loaded entity.
   */
  upsertValue(selector, value, state) {
    this.update(d => this._upsertEntities(selector(d), [value], state || {}));
  }
  /**
  * Call a HTTP route to delete an entity by key
  *
  * @param url            URL to call
  * @param root           Store entities containing the entity to delete
  * @param key            Key to find the entity in the entities
  */
  deleteEntityByKey(url, root, key) {
    return this._innerFrom(() => {
      const entity = this.findEntityByKey(root, key);
      if (entity === null) {
        return throwError(() => 'The entity was not found in the store');
      }
      if (entity.deleting === true) {
        return throwError(() => 'The entity is already being deleted by another process');
      }
      this.update(d => {
        this._setEntitiesStates(d, root, null, null, null, true);
        this._setEntityStates(d, root, key, null, null, null, true);
      });
      return this._http.delete(url).pipe(finalize(() => {
        this.update(d => {
          this._setEntitiesStates(d, root, null, null, null, false);
          this._setEntityStates(d, root, key, null, null, null, false);
        });
      }));
    });
  }
  /**
   *
   * @param url               URL to call
   * @param root              Store location where to load the entities
   * @param dependentRoot     Dependant store location
   * @param dependentKeys     Keys of depending entites
   * @param stateProperty     Property determining if the entity has to be loaded
   * @param entitiesLoaded    Defines whether the entity are fully loaded
   * @param force     By-pass the loaded check
   */
  loadBatchEntities(url, root, dependentRoot, dependentKeys, stateProperty, entitiesLoaded = true, force = false) {
    return this._innerFrom(() => {
      const entities = dependentKeys.map(k => ({
        key: k,
        state: (this.findValueByKey(dependentRoot, k) || {})[stateProperty]
      })).filter(i => _isUndefined(i.state) || i.state === false || force === true);
      if (entities.length !== 0) {
        this.update(d => {
          this._setEntitiesStates(d, root, null, true, null, null);
          entities.forEach(e => {
            const value = this.findValueByKey(dependentRoot, e.key, d);
            if (value !== null) {
              value[stateProperty] = null;
            }
          });
        });
        url = url.endsWith('/') === false ? `${url}/?ids=${entities.map(e => e.key).join('&ids=')}` : `${url}/?ids=${entities.map(e => e.key).join('&ids=')}`;
        const query = this._getLoadQuery(url);
        return query.pipe(map(data => data), tap(data => this.upsertValues(root, data, {
          loaded: entitiesLoaded
        })), tap(() => this.update(d => {
          entities.forEach(e => {
            const value = this.findValueByKey(dependentRoot, e.key, d);
            if (value !== null) {
              value[stateProperty] = true;
            }
          });
        })), catchError(err => {
          this.update(d => {
            entities.forEach(e => {
              const value = this.findValueByKey(dependentRoot, e.key, d);
              if (value !== null) {
                value[stateProperty] = e.state;
              }
            });
          });
          return throwError(() => new Error(err));
        }), finalize(() => {
          this.update(d => this._setEntitiesStates(d, root, null, false, null, null));
          this._removeLoadQuery(url);
        }));
      }
      return of([]);
    });
  }
  /**
   * Load all the entities
   *
   * @param url               URL to call
   * @param root              Store location to place the loaded entities
   * @param entitiesLoaded    Defines whether the entities are compltely loaded or not
   * @param force             Ignore the "loaded" state
   */
  loadAllEntities(url, root, entitiesLoaded = true, force = false) {
    return this._innerFrom(() => {
      const state = root(this.value).loaded;
      if (state !== true || force === true) {
        this.update(d => {
          this._setEntitiesStates(d, root, null, true, null, null);
          root(d).loaded = null;
        });
        return this._getLoadQuery(url).pipe(map(data => Array.isArray(data) ? data : [data]), map(data => data), tap(data => this.upsertValues(root, data, {
          loaded: entitiesLoaded
        })), tap(() => this.update(d => {
          root(d).loaded = true;
        })), catchError(err => {
          this.update(d => {
            root(d).loaded = state;
          });
          return throwError(() => new Error(err));
        }), finalize(() => {
          this.update(d => this._setEntitiesStates(d, root, null, false, null, null));
          this._removeLoadQuery(url);
        }));
      }
      return of(this.getValues(root));
    });
  }
  /**
   * Load entities once based on a custom condition
   *
   * @param url               URL to call to load the entities
   * @param root              Selector to get the location of the store where the entities will be loaded
   * @param entitiesLoaded    Defines whether the retrieved entities are considered as loaded or not
   * @param force             Used to retrieve the entities no matter what
   */
  loadEntitiesOnce(url, root, entitiesLoaded = true, force = false) {
    return this._innerFrom(() => {
      if (this._executedQueries.has(url) === false || force === true) {
        this.update(d => this._setEntitiesStates(d, root, null, true, null, null));
        return this._getLoadQuery(url).pipe(map(data => Array.isArray(data) ? data : [data]), map(data => data), tap(data => this.upsertValues(root, data, {
          loaded: entitiesLoaded
        })), tap(() => {
          this._executedQueries.add(url);
          this._executedQueriesSubject.next(this._executedQueries);
        }), finalize(() => {
          this.update(d => this._setEntitiesStates(d, root, null, false, null, null));
          this._removeLoadQuery(url);
        }));
      }
      return of([]);
    });
  }
  /**
   * Load entities based on a custom optional condition
   *
   * @param url               URL to call to load the entities
   * @param root              Selector to get the location of the store where the entities will be loaded
   * @param dependentRoot     Selector to retrieve the location where the variable defining whether the entities are loading or not is located
   * @param stateProperty     Name of the property defining whether the entities are loading or not is located
   * @param entitiesLoaded    Defines whether the retrieved entities are considered as loaded or not
   * @param options           Loading options
   * @param options.force     Used to retrieve the entities no matter what
   * @param options.mapper    Used to convert the data returned by the server in entity type
   */
  loadEntities(url, root, dependentRoot, stateProperty, entitiesLoaded = true, force = false) {
    return this._innerFrom(() => {
      const dependentEntity = dependentRoot(this.value);
      if (dependentEntity === null) {
        throw new Error('The dependent entity could not be found!');
      }
      const state = dependentEntity[stateProperty];
      if (state !== true || force === true) {
        this.update(d => {
          this._setEntitiesStates(d, root, null, true, null, null);
          dependentRoot(d)[stateProperty] = null;
        });
        return this._getLoadQuery(url).pipe(map(data => Array.isArray(data) ? data : [data]), map(data => data), tap(data => this.upsertValues(root, data, {
          loaded: entitiesLoaded
        })), tap(() => dependentRoot && this.update(d => {
          dependentRoot(d)[stateProperty] = true;
        })), catchError(err => {
          dependentRoot && this.update(d => {
            dependentRoot(d)[stateProperty] = state;
          });
          return throwError(() => new Error(err));
        }), finalize(() => {
          this.update(d => this._setEntitiesStates(d, root, null, false, null, null));
          this._removeLoadQuery(url);
        }));
      }
      return of([]);
    });
  }
  /**
   * Load an entity based on a custom condition
   *
   * @param url               URL to call to load the entity
   * @param root              Selector to get the location of the store where the entities will be loaded
   * @param dependentRoot     Selector to retrieve the location where the variable defining whether the entities are loading or not is located
   * @param stateProperty     Name of the property defining whether the entities are loading or not is located
   * @param entityLoaded      Defines whether the retrieved entity is considered as loaded or not
   * @param force             Used to retrieve the entities no matter what
   */
  loadEntity(url, root, dependentRoot, stateProperty, entityLoaded = true, force = false) {
    return this._innerFrom(() => {
      const state = dependentRoot(this.value)[stateProperty];
      if (state !== true || force === true) {
        this.update(d => {
          this._setEntitiesStates(d, root, null, true, null, null);
          dependentRoot(d)[stateProperty] = null;
        });
        return this._getLoadQuery(url).pipe(map(data => data), tap(data => this.upsertValue(root, data, {
          loaded: entityLoaded
        })), tap(() => dependentRoot && this.update(d => {
          dependentRoot(d)[stateProperty] = true;
        })), catchError(err => {
          this.update(d => {
            dependentRoot(d)[stateProperty] = state;
          });
          return throwError(() => new Error(err));
        }), finalize(() => {
          this.update(d => this._setEntitiesStates(d, root, null, false, null, null));
          this._removeLoadQuery(url);
        }));
      }
      return of(null);
    });
  }
  /**
   * Loads an entity by some criteria defined by selector. Used to load a partially loaded entity already present in the store or to load a non existing entity in the store.
   *
   * @param url               URL called to load the entity
   * @param root              Location of the store the entity is put
   * @param selector          Delegates used to find the entity. If multiple entity match the criteria, only the first one will be loaded
   * @param entityLoaded      Defines whether the retrieved entity is considered as loaded or not
   * @param force             By default, the entity is loaded only if it does not exist in the store or is not loaded. Use force to load it no matter what.
   */
  loadEntityBy(url, root, selector, entityLoaded = true, force = false) {
    return this._loadEntity(url, root, selector, entityLoaded, force);
  }
  /**
   * Loads an entity by key. Used to load a partially loaded entity already present in the store or to load a non existing entity in the store.
   *
   * @param url               URL called to load the entity
   * @param root              Location of the store the entity is put
   * @param key               Key of the entity to load
   * @param entityLoaded      Defines whether the retrieved entity is considered as loaded or not
   * @param force             By default, the entity is loaded only if it does not exist in the store or is not loaded. Use force to load it no matter what.
   */
  loadEntityByKey(url, root, key, entityLoaded = true, force = false) {
    return this._loadEntity(url, root, key, entityLoaded, force);
  }
  /**
   * Call a HTTP route to create a new entity
   *
   * @param url               URL to call
   * @param root              Store location where to put the entity
   * @param data              Data to post
   */
  postEntity(url, root, data) {
    return this._innerFrom(() => {
      this.update(d => this._setEntitiesStates(d, root, true, null, null, null));
      return this._http.post(url, data).pipe(finalize(() => this.update(d => this._setEntitiesStates(d, root, false, null, null, null))));
    });
  }
  /**
   * Call an HTTP route to update all entities
   *
   * @param url                 URL to call
   * @param root                Store entities containing the entities to update
   * @param data                Updated data
   */
  putAllEntities(url, root, data) {
    return this._innerFrom(() => {
      const existingIds = this.getValues(root).map(v => v.id);
      const newEntities = data.filter(d => existingIds.includes(d.id) === false);
      this.update(d => {
        this._setEntitiesStates(d, root, newEntities.length !== 0 ? true : null, null, newEntities.length === 0 && data.length !== 0 ? true : null, null);
        for (const id of existingIds) {
          this._setEntityStates(d, root, id, null, null, true, null);
        }
      });
      return this._http.put(url, data).pipe(finalize(() => {
        this.update(d => {
          this._setEntitiesStates(d, root, newEntities.length !== 0 ? false : null, null, newEntities.length === 0 && data.length !== 0 ? false : null, null);
          for (const id of existingIds) {
            this._setEntityStates(d, root, id, null, null, false, null);
          }
        });
      }));
    });
  }
  /**
   * Call a HTTP route to update an existing entity
   *
   * @param url               URL to call
   * @param root              Store entities containing the entity to update
   * @param key               Key of the entity to update
   * @param data              Updated data
   */
  putEntityByKey(url, root, key, data) {
    return this._innerFrom(() => {
      const entity = this.findEntityByKey(root, key);
      const state = entity ? entity.loaded : false;
      this.update(d => {
        this._setEntitiesStates(d, root, entity === null ? true : null, null, entity !== null ? true : null, null);
        this._setEntityStates(d, root, key, null, null, true, null);
      });
      return this._http.put(url, data).pipe(finalize(() => {
        this.update(d => {
          this._setEntitiesStates(d, root, entity === null ? false : null, null, entity !== null ? false : null, null);
          this._setEntityStates(d, root, key, state, null, false, null);
        });
      }));
    });
  }
  /**
   * Rebuild the indices map of an entity list
   *
   * @param root Store entities to rebuild the indices of
   */
  rebuildIndices(root) {
    this.update(store => {
      const entities = root(store);
      for (const index of entities._indiceNames) {
        entities._indices[index] = new Map();
      }
      for (let i = 0, length = entities._array.length; i < length; ++i) {
        const entity = entities._array[i];
        for (const index of entities._indiceNames) {
          const value = entity.value[index];
          const map = entities._indices[index];
          if (map.has(value)) {
            map.get(value).push(i);
          } else {
            map.set(value, [i]);
          }
        }
      }
    });
  }
  /********************************************************************** PRIVATE **********************************************************************/
  /** */
  _setEntityState(root, state) {
    Object.assign(root, state || {});
  }
  /** */
  _updateEntityByKey(draft, snapshot, selector, key, updater) {
    const snapshotRoot = selector(snapshot);
    const draftRoot = selector(draft);
    if (snapshotRoot && snapshotRoot._entities.has(key)) {
      const position = snapshotRoot._entities.get(key);
      const entity = draftRoot._array[position];
      if (entity) {
        for (const index of snapshotRoot._indiceNames) {
          const value = entity.value[index];
          const array = (draftRoot._indices[index].get(value) || []).filter(p => p !== position);
          draftRoot._indices[index].set(value, array);
        }
        updater(entity);
        for (const index of snapshotRoot._indiceNames) {
          const value = entity.value[index];
          if (draftRoot._indices[index].has(value)) {
            draftRoot._indices[index].get(value).push(position);
          } else {
            draftRoot._indices[index].set(value, [position]);
          }
        }
      }
    }
  }
  /** */
  _upsertEntities(root, values, state) {
    for (const value of values) {
      const entityState = {
        ...state
      };
      const position = root._entities.get(value.id);
      const existing = position === undefined ? null : root._array[position];
      if (existing) {
        const position = root._entities.get(value.id);
        for (const index of root._indiceNames) {
          if (index in value && value[index] !== existing.value[index]) {
            const map = root._indices[index];
            const oldValue = existing.value[index];
            const newValue = value[index];
            if (_isUndefined(oldValue) === false) {
              map.set(oldValue, (map.get(oldValue) || []).filter(p => p !== position));
            }
            map.set(newValue, (map.get(newValue) ?? []).concat(position));
          }
        }
        existing.value = {
          ...existing.value,
          ...value
        };
        if (entityState?.loaded === false && existing.loaded === true) {
          entityState.loaded = true;
        }
        this._setEntityState(existing, entityState);
      } else {
        const entity = createEntity(value);
        root._array.push(entity);
        const position = root._array.length - 1;
        root._entities.set(value.id, position);
        for (const index of root._indiceNames) {
          if (index in value) {
            const indexValue = value[index];
            const map = root._indices[index];
            if (map.has(indexValue)) {
              (map.get(indexValue) || []).push(position);
            } else {
              map.set(indexValue, [position]);
            }
          }
        }
        this._setEntityState(entity, state);
      }
    }
  }
  /* */
  _getLoadQuery(data) {
    let query = this._executingQueries.get(typeof data == 'string' ? data : data.key);
    if (!query) {
      query = typeof data == 'string' ? this._http.get(data).pipe(share()) : data.observable.pipe(share());
      this._executingQueries.set(typeof data == 'string' ? data : data.key, query);
    }
    return query;
  }
  /* */
  _innerFrom(inner) {
    return new Observable(observer => {
      const s = inner().subscribe({
        next: x => observer.next(x),
        error: err => observer.error(err),
        complete: () => observer.complete()
      });
      return {
        unsubscribe: () => s.unsubscribe()
      };
    });
  }
  /** */
  _loadEntity(url, root, selector, entityLoaded = true, force = false) {
    return this._innerFrom(() => {
      let hasFailed = true;
      const entity = typeof selector === 'function' ? this.findEntityBy(root, entity => selector(entity.value)) : this.findEntityByKey(root, selector);
      const state = entity ? entity.loaded : false;
      if (entity === null || state === false || force === true) {
        this.update(d => {
          this._setEntitiesStates(d, root, null, true, null, null);
          entity !== null && this._setEntityStates(d, root, entity.value.id, null, true, null, null);
        });
        return this._getLoadQuery(url).pipe(map(data => data), tap(data => this.upsertValue(root, data, {
          loaded: entityLoaded
        })), tap(data => this.update(d => this._setEntityStates(d, root, data.id, entityLoaded, false, null, null))), tap(() => hasFailed = false), finalize(() => {
          this.update(d => {
            this._setEntityStates(d, root, entity?.value?.id, hasFailed === true ? false : undefined, false, null, null);
            this._setEntitiesStates(d, root, null, false, null, null);
          });
          this._removeLoadQuery(url);
        }));
      }
      return of(entity?.value || null);
    });
  }
  /** */
  _removeLoadQuery(data) {
    this._executingQueries.delete(typeof data == 'string' ? data : data.key);
  }
  /** */
  _setEntitiesStates(draft, root, adding = null, loading = null, updating = null, deleting = null) {
    const entities = root(draft);
    if (entities) {
      this._updateStateMaps(this._addingStates, entities.uid, adding);
      this._updateStateMaps(this._loadingStates, entities.uid, loading);
      this._updateStateMaps(this._updatingStates, entities.uid, updating);
      this._updateStateMaps(this._deletingStates, entities.uid, deleting);
      entities.loading = (this._loadingStates.get(entities.uid) || 0) > 0;
      entities.adding = !!this._addingStates.get(entities.uid);
      entities.busy = (this._addingStates.get(entities.uid) || 0) + (this._loadingStates.get(entities.uid) || 0) + (this._updatingStates.get(entities.uid) || 0) + (this._deletingStates.get(entities.uid) || 0) > 0;
    }
  }
  /** */
  _setEntityStates(draft, root, key, loaded = null, loading = null, updating = null, deleting = null) {
    let entity = key !== null ? this.findEntityByKey(root, key, draft) : root(draft);
    if (entity !== null && key === null && '_array' in entity) {
      entity = null;
    }
    if (entity) {
      this._updateStateMaps(this._loadingStates, entity.uid, loading);
      this._updateStateMaps(this._updatingStates, entity.uid, updating);
      this._updateStateMaps(this._deletingStates, entity.uid, deleting);
      if (loaded !== null) {
        entity.loaded = loaded;
      }
      entity.loading = (this._loadingStates.get(entity.uid) || 0) > 0;
      entity.updating = (this._updatingStates.get(entity.uid) || 0) > 0;
      entity.deleting = (this._deletingStates.get(entity.uid) || 0) > 0;
      entity.busy = entity.loading || entity.deleting || entity.updating;
    }
  }
  /** */
  _updateStateMaps(map, id, flag) {
    if (flag === true) {
      map.set(id, (map.get(id) || 0) + 1);
    } else if (flag === false) {
      map.set(id, (map.get(id) || 0) - 1);
    }
  }
  static {
    this.ɵfac = function NgStore_Factory(t) {
      return new (t || NgStore)(i0.ɵɵinject(NG_STORE_CONFIG));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: NgStore,
      factory: NgStore.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgStore, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NG_STORE_CONFIG]
    }]
  }], null);
})();
class StoreComponent {
  constructor() {
    this._store = inject(NgStore);
  }
  static {
    this.ɵfac = function StoreComponent_Factory(t) {
      return new (t || StoreComponent)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: StoreComponent
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StoreComponent, [{
    type: Directive
  }], null, null);
})();
class NgForTrackByValueDirective {
  /****************************************************************** LIFE CYCLE ******************************************************************/
  constructor() {
    /****************************************************************** VARIABLES ******************************************************************/
    this._ngFor = inject(NgForOf, {
      self: true
    });
    this._ngFor.ngForTrackBy = (_, item) => item.id;
  }
  static {
    this.ɵfac = function NgForTrackByValueDirective_Factory(t) {
      return new (t || NgForTrackByValueDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgForTrackByValueDirective,
      selectors: [["", "ngForTrackByValue", ""]],
      inputs: {
        ngForOf: "ngForOf"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgForTrackByValueDirective, [{
    type: Directive,
    args: [{
      selector: '[ngForTrackByValue]',
      standalone: true
    }]
  }], () => [], {
    ngForOf: [{
      type: Input
    }]
  });
})();
class NgForTrackByEntityDirective {
  /****************************************************************** LIFE CYCLE ******************************************************************/
  constructor() {
    /****************************************************************** VARIABLES ******************************************************************/
    this._ngFor = inject(NgForOf, {
      self: true
    });
    this._ngFor.ngForTrackBy = (_, item) => item.value.id;
  }
  static {
    this.ɵfac = function NgForTrackByEntityDirective_Factory(t) {
      return new (t || NgForTrackByEntityDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: NgForTrackByEntityDirective,
      selectors: [["", "ngForTrackByEntity", ""]],
      inputs: {
        ngForOf: "ngForOf"
      },
      standalone: true
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgForTrackByEntityDirective, [{
    type: Directive,
    args: [{
      selector: '[ngForTrackByEntity]',
      standalone: true
    }]
  }], () => [], {
    ngForOf: [{
      type: Input
    }]
  });
})();
class NgStoreErrorHostComponent {
  /****************************************************************** LIFE CYCLE ******************************************************************/
  constructor(_config) {
    this._config = _config;
  }
  ngOnInit() {
    this.host.clear();
    if (this._config.errorComponent) {
      const component = this.host.createComponent(this._config.errorComponent);
      component.instance.error = this.error;
    }
  }
  static {
    this.ɵfac = function NgStoreErrorHostComponent_Factory(t) {
      return new (t || NgStoreErrorHostComponent)(i0.ɵɵdirectiveInject(NG_STORE_CONFIG));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgStoreErrorHostComponent,
      selectors: [["ngs-error-host"]],
      viewQuery: function NgStoreErrorHostComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7, ViewContainerRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.host = _t.first);
        }
      },
      inputs: {
        error: "error"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 0,
      consts: [["host", ""]],
      template: function NgStoreErrorHostComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementContainer(0, null, 0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgStoreErrorHostComponent, [{
    type: Component,
    args: [{
      selector: 'ngs-error-host',
      template: '<ng-container #host></ng-container>',
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NG_STORE_CONFIG]
    }]
  }], {
    error: [{
      type: Input
    }],
    host: [{
      type: ViewChild,
      args: ['host', {
        read: ViewContainerRef,
        static: true
      }]
    }]
  });
})();
class NgStoreLoaderHostComponent {
  /****************************************************************** LIFE CYCLE ******************************************************************/
  constructor(_config) {
    this._config = _config;
  }
  ngOnInit() {
    const component = this.host.createComponent(this._config.loaderComponent);
    component.instance.size = this.size;
  }
  static {
    this.ɵfac = function NgStoreLoaderHostComponent_Factory(t) {
      return new (t || NgStoreLoaderHostComponent)(i0.ɵɵdirectiveInject(NG_STORE_CONFIG));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgStoreLoaderHostComponent,
      selectors: [["ngs-loader-host"]],
      viewQuery: function NgStoreLoaderHostComponent_Query(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵviewQuery(_c0, 7, ViewContainerRef);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.host = _t.first);
        }
      },
      inputs: {
        size: "size"
      },
      standalone: true,
      features: [i0.ɵɵStandaloneFeature],
      decls: 2,
      vars: 0,
      consts: [["host", ""]],
      template: function NgStoreLoaderHostComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementContainer(0, null, 0);
        }
      },
      encapsulation: 2,
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgStoreLoaderHostComponent, [{
    type: Component,
    args: [{
      selector: 'ngs-loader-host',
      template: '<ng-container #host></ng-container>',
      standalone: true,
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [NG_STORE_CONFIG]
    }]
  }], {
    size: [{
      type: Input
    }],
    host: [{
      type: ViewChild,
      args: ['host', {
        read: ViewContainerRef,
        static: true
      }]
    }]
  });
})();
class NgStoreContainerComponent {
  constructor() {
    /****************************************************************** SERVICES ******************************************************************/
    this.config = inject(NG_STORE_CONFIG);
    this._cdr = inject(ChangeDetectorRef);
    this.loaderType = 'component';
    this.loaderSize = this.config.initialLoaderSize || '1rem';
    this.loaderText = this.config.defaultLoaderText && this.config.defaultLoaderText() || 'Loading...';
    this.loaderTemplate = null;
    this.errorTemplate = null;
    this.changed = new EventEmitter();
    this._subscription = null;
  }
  /****************************************************************** BINDINGS ******************************************************************/
  get query$() {
    return this._query$;
  }
  set query$(value) {
    this._query$ = value && value.pipe(mapToError(), shareReplay(1));
    this.loaded$ = value ? this._query$.pipe(map(x => x === null), distinctUntilChanged()) : of(false);
  }
  get data$() {
    return this._data$;
  }
  set data$(value) {
    this._data$ = value && value.pipe(shareReplay(1));
  }
  /****************************************************************** LIFE CYCLE ******************************************************************/
  ngOnChanges() {
    this._subscription?.unsubscribe();
    if (this.data$ && this.loaded$) {
      this._subscription = this.loaded$.pipe(filter(x => x === true), distinctUntilChanged(), switchMap(() => this.data$), distinctUntilChanged(), tap(data => this.changed.emit(data)), delay(0)).subscribe(() => this._cdr.markForCheck());
    }
  }
  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }
  static {
    this.ɵfac = function NgStoreContainerComponent_Factory(t) {
      return new (t || NgStoreContainerComponent)();
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: NgStoreContainerComponent,
      selectors: [["ngs-container"]],
      contentQueries: function NgStoreContainerComponent_ContentQueries(rf, ctx, dirIndex) {
        if (rf & 1) {
          i0.ɵɵcontentQuery(dirIndex, TemplateRef, 5);
        }
        if (rf & 2) {
          let _t;
          i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
        }
      },
      inputs: {
        query$: "query$",
        loaded$: "loaded$",
        data$: "data$",
        loaderType: "loaderType",
        loaderSize: "loaderSize",
        loaderText: "loaderText",
        loaderTemplate: "loaderTemplate",
        errorTemplate: "errorTemplate"
      },
      outputs: {
        changed: "changed"
      },
      standalone: true,
      features: [i0.ɵɵNgOnChangesFeature, i0.ɵɵStandaloneFeature],
      decls: 5,
      vars: 1,
      consts: [["contentTpl", ""], ["errorTpl", ""], ["dataTpl", ""], [4, "ngIf"], [4, "ngIf", "ngIfElse"], [4, "ngTemplateOutlet", "ngTemplateOutletContext"], ["class", "loader", 4, "ngIf"], [1, "loader"], [3, "size"], [4, "ngTemplateOutlet"], [3, "error", 4, "ngIf"], [3, "error"], [4, "ngIf", "ngIfThen", "ngIfElse"]],
      template: function NgStoreContainerComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, NgStoreContainerComponent_ng_template_0_Template, 4, 4, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor)(2, NgStoreContainerComponent_ng_template_2_Template, 3, 3, "ng-template", null, 1, i0.ɵɵtemplateRefExtractor)(4, NgStoreContainerComponent_ng_container_4_Template, 3, 5, "ng-container", 3);
        }
        if (rf & 2) {
          i0.ɵɵadvance(4);
          i0.ɵɵproperty("ngIf", ctx.query$ && ctx.loaded$);
        }
      },
      dependencies: [AsyncPipe, JsonPipe, NgIf, NgStoreErrorHostComponent, NgStoreLoaderHostComponent, NgTemplateOutlet],
      styles: [".loader[_ngcontent-%COMP%]{display:flex;flex-direction:row;justify-content:center;align-items:center}"],
      changeDetection: 0
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgStoreContainerComponent, [{
    type: Component,
    args: [{
      selector: 'ngs-container',
      standalone: true,
      imports: [AsyncPipe, JsonPipe, NgIf, NgStoreErrorHostComponent, NgStoreLoaderHostComponent, NgTemplateOutlet],
      changeDetection: ChangeDetectionStrategy.OnPush,
      template: "<ng-template #contentTpl>\r\n  <ng-template #dataTpl>\r\n    <ng-container *ngTemplateOutlet=\"template; context: { $implicit: !!data$ ? (data$ | async) : null }\"></ng-container>\r\n  </ng-template>\r\n  <ng-container *ngIf=\"(loaded$ | async) !== true; else dataTpl\">\r\n    <div class=\"loader\"\r\n         *ngIf=\"loaderType === 'component'\">\r\n      <ngs-loader-host [size]=\"loaderSize\"></ngs-loader-host>\r\n    </div>\r\n    <ng-container *ngIf=\"loaderType === 'text'\">{{ loaderText }}</ng-container>\r\n    <ng-container *ngIf=\"loaderType === 'template'\">\r\n      <ng-container *ngTemplateOutlet=\"loaderTemplate\"></ng-container>\r\n    </ng-container>\r\n  </ng-container>\r\n</ng-template>\r\n\r\n<ng-template #errorTpl\r\n             let-error>\r\n  <pre *ngIf=\"!config.errorComponent && errorTemplate === null\">{{ error | json }}</pre>\r\n  <ngs-error-host [error]=\"error\"\r\n                  *ngIf=\"config.errorComponent && errorTemplate === null\"></ngs-error-host>\r\n  <ng-container *ngIf=\"errorTemplate !== null\">\r\n    <ng-container *ngTemplateOutlet=\"errorTemplate; context: { $implicit: error }\"></ng-container>\r\n  </ng-container>\r\n</ng-template>\r\n\r\n<ng-container *ngIf=\"query$ && loaded$\">\r\n  <ng-container *ngIf=\"query$ | async; then errorTpl; else contentTpl\"></ng-container>\r\n</ng-container>",
      styles: [".loader{display:flex;flex-direction:row;justify-content:center;align-items:center}\n"]
    }]
  }], null, {
    query$: [{
      type: Input
    }],
    loaded$: [{
      type: Input
    }],
    data$: [{
      type: Input
    }],
    loaderType: [{
      type: Input
    }],
    loaderSize: [{
      type: Input
    }],
    loaderText: [{
      type: Input
    }],
    loaderTemplate: [{
      type: Input
    }],
    errorTemplate: [{
      type: Input
    }],
    template: [{
      type: ContentChild,
      args: [TemplateRef]
    }],
    changed: [{
      type: Output
    }]
  });
})();
function trackByEntity(_, entity) {
  return entity.value.id;
}
function trackByValue(_, entity) {
  return entity.id;
}
const provideStore = config => makeEnvironmentProviders([NgStore, {
  provide: NG_STORE_CONFIG,
  useValue: config
}]);
class NgStoreModule {
  static {
    this.ɵfac = function NgStoreModule_Factory(t) {
      return new (t || NgStoreModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: NgStoreModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NgStoreModule, [{
    type: NgModule,
    args: [{
      imports: [NgStoreContainerComponent],
      exports: [NgStoreContainerComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { NG_STORE_CONFIG, NgForTrackByEntityDirective, NgForTrackByValueDirective, NgStore, NgStoreContainerComponent, NgStoreModule, StoreComponent, createEntities, createEntity, findStoreValueByKey, provideStore, trackByEntity, trackByValue };
