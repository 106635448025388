import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { GlobalService } from '@app/services';
import { LoaderComponent } from '@sfpd/ng-ui/loader';
import { MessageComponent } from '@sfpd/ng-ui/message';
import { ToasterComponent } from '@sfpd/ng-ui/toaster';
import { ToolbarComponent } from '../toolbar/toolbar.component';

@Component({
  selector: 'noti-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
    ToasterComponent,
    ToolbarComponent,
    RouterOutlet,
    MessageComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(public globalService: GlobalService) { }

}
