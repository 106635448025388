import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Application } from '@app/models';
import { ApplicationService } from '@app/services';
import { AppState } from '@app/store';
import { NgStoreContainerComponent, StoreComponent } from '@sfpd/ng-store';
import { ButtonComponent, ButtonGroupComponent } from '@sfpd/ng-ui/button';
import { DialogButtonsComponent, DialogComponent, DialogContentComponent } from '@sfpd/ng-ui/dialog';
import { IconComponent } from '@sfpd/ng-ui/icon';
import { TableBodyEmptyRowDirective, TableBodyRowDirective, TableComponent, TableHeaderComponent, TableHeaderRowDirective } from '@sfpd/ng-ui/table';
import { ToasterService } from '@sfpd/ng-ui/toaster';
import { finalize, of } from 'rxjs';
import { ApplicationUpsertFormComponent } from '../application-upsert-form/application-upsert-form.component';

@Component({
  selector: 'noti-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    NgStoreContainerComponent,
    ApplicationUpsertFormComponent,
    TableComponent,
    TableHeaderComponent,
    TableHeaderRowDirective,
    TableBodyEmptyRowDirective,
    TableBodyRowDirective,
    DialogComponent,
    DialogContentComponent,
    DialogButtonsComponent,
    ButtonComponent,
    ButtonGroupComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApplicationsComponent extends StoreComponent<AppState, Application[]> implements OnInit {

  /******************************************************** BINDINGS ********************************************************/

  @ViewChild(DialogComponent)
  public dialog!: DialogComponent;

  /******************************************************** VARIABLES ********************************************************/

  public current: number | null = null;
  public resetting = false;
  public upsertFormVisible = false;

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(
    private _applicationService: ApplicationService,
    private _toastService: ToasterService,
    private _cdr: ChangeDetectorRef
  ) {
    super();
  }

  public ngOnInit(): void {
    this.query$ = of(null);
    this.data$ = this._store.selectValues(s => s.applications);
  }

  /******************************************************** PUBLIC ********************************************************/

  /** */
  public hideResetDialog() {
    this.current = null;
    this.dialog.close();
  }

  /** */
  public reset() {
    this.resetting = true;

    this._applicationService
      .reset(this.current!)
      .pipe(
        finalize(() => {
          this.resetting = false;
          this.hideResetDialog();
          this._cdr.markForCheck();
        })
      )
      .subscribe({
        error: err => this._toastService.error(err)
      })
  }

  /**
   * 
   * @param id
   */
  public showUpsertForm(id: number | null) {
    this.current = id;
    this.upsertFormVisible = true;
  }

  /** */
  public showResetDialog(id: number) {
    this.current = id;
    this.dialog.open();
  }

}
