import * as i0 from '@angular/core';
import { Directive, EventEmitter, ElementRef, inject, booleanAttribute, Input, Output, Injectable, InjectionToken, computed, signal, makeEnvironmentProviders } from '@angular/core';
import { Subject, takeUntil, merge, EMPTY, delay, filter, map, fromEvent, Observable } from 'rxjs';
function afterUi(func) {
  setTimeout(() => func());
}
class DestroyableComponent {
  constructor() {
    /******************************************************** VARIABLES ********************************************************/
    this._destroy$ = new Subject();
  }
  /******************************************************** LIFE CYCLE ********************************************************/
  ngOnDestroy() {
    this._destroy$.next();
  }
  static {
    this.ɵfac = function DestroyableComponent_Factory(t) {
      return new (t || DestroyableComponent)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: DestroyableComponent
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DestroyableComponent, [{
    type: Directive
  }], null, null);
})();
class FocusManager {
  /******************************************************** PUBLIC ********************************************************/
  /**
   *
   * @param element
   * @param until$
   */
  listen(element, until$ = EMPTY, ...events) {
    const observables$ = events.map(e => {
      switch (e) {
        case 'blur':
          return this._blur(element);
        case 'blur-within':
          return this._blurWithin(element);
        case 'focus':
          return this._focus(element);
        case 'focus-within':
          return this._focusWithin(element);
      }
    }).filter(o => o !== null);
    return !!until$ ? merge(...observables$).pipe(takeUntil(until$)) : merge(...observables$);
  }
  /******************************************************** PRIVATE ********************************************************/
  /** */
  _blur(element) {
    return fromEvent(element, 'blur', {
      capture: true
    }).pipe(delay(0), filter(() => this._isDescendantOf(document.activeElement, element) === false), map(() => ({
      event: 'blur'
    })));
  }
  /** */
  _blurWithin(element) {
    return fromEvent(element, 'blur', {
      capture: true
    }).pipe(delay(0), filter(e => this._isDescendantOf(e.target, element) === true), map(() => ({
      event: 'blur-within'
    })));
  }
  /** */
  _focus(element) {
    return fromEvent(element, 'focusin').pipe(filter(e => this._isDescendantOf(e.relatedTarget, element) === false && this._isDescendantOf(e.target, element) === true), map(() => ({
      event: 'focus'
    })));
  }
  /** */
  _focusWithin(element) {
    return fromEvent(element, 'focusin').pipe(filter(e => this._isDescendantOf(e.target, element, true) === true), map(() => ({
      event: 'focus-within'
    })));
  }
  /**
   *
   * @param element
   * @param parent
   */
  _isDescendantOf(element, parent, ignoreParent = false) {
    let e = ignoreParent === true ? element.parentElement : element;
    while (e) {
      if (e === parent) {
        return true;
      }
      e = e.parentElement;
    }
    return false;
  }
}
class BaseFormControl extends DestroyableComponent {
  constructor() {
    super(...arguments);
    /******************************************************** BINDINGS ********************************************************/
    this.disabled = false;
    this.blur = new EventEmitter();
    /******************************************************** VARIABLES ********************************************************/
    this._elementRef = inject(ElementRef);
    this._onTouched = null;
    this._focusManager = new FocusManager();
  }
  /******************************************************** LIFE CYCLE ********************************************************/
  ngOnInit() {
    this._focusManager.listen(this._elementRef.nativeElement, this._destroy$, 'blur').subscribe(() => {
      this._onTouched && this._onTouched();
      this.blur.next();
    });
  }
  /******************************************************** CONTROL VALUE ACCESSOR ********************************************************/
  /** */
  registerOnChange(fn) {
    this._onChange = fn;
  }
  /** */
  registerOnTouched(fn) {
    this._onTouched = fn;
  }
  /** */
  setDisabledState(isDisabled) {
    this.disabled = isDisabled;
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵBaseFormControl_BaseFactory;
      return function BaseFormControl_Factory(t) {
        return (ɵBaseFormControl_BaseFactory || (ɵBaseFormControl_BaseFactory = i0.ɵɵgetInheritedFactory(BaseFormControl)))(t || BaseFormControl);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: BaseFormControl,
      inputs: {
        disabled: [2, "disabled", "disabled", booleanAttribute]
      },
      outputs: {
        blur: "blur"
      },
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BaseFormControl, [{
    type: Directive
  }], null, {
    disabled: [{
      type: Input,
      args: [{
        transform: booleanAttribute
      }]
    }],
    blur: [{
      type: Output
    }]
  });
})();
function coerceBoolean(value) {
  return value !== null && `${value}` !== 'false';
}
class ColorModeManager {
  /******************************************************** ACCESSORS ********************************************************/
  get mode() {
    let value = localStorage.getItem('ng-ui-color-mode');
    if (value !== null) {
      return value;
    }
    value = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches && 'dark' || 'light';
    return value;
  }
  set mode(value) {
    localStorage.setItem('ng-ui-color-mode', value);
  }
  /******************************************************** LIFE CYCLE ********************************************************/
  constructor() {
    if (this.mode === 'light') {
      this._setLightMode();
    } else {
      this._setDarkMode();
    }
  }
  /******************************************************** PUBLIC ********************************************************/
  toggle() {
    if (this.mode === 'light') {
      this._setDarkMode();
    } else {
      this._setLightMode();
    }
  }
  /******************************************************** PRIVATE ********************************************************/
  /* */
  _setLightMode() {
    document.body.classList.remove('ng-ui-dark-mode');
    this.mode = 'light';
    document.querySelector(':root').style.setProperty('--ng-ui-background-color', '#fff');
    document.querySelector(':root').style.setProperty('--ng-ui-foreground-color', '#121212');
  }
  /* */
  _setDarkMode() {
    document.body.classList.add('ng-ui-dark-mode');
    this.mode = 'dark';
    document.querySelector(':root').style.setProperty('--ng-ui-background-color', '#121212');
    document.querySelector(':root').style.setProperty('--ng-ui-foreground-color', '#fff');
  }
  static {
    this.ɵfac = function ColorModeManager_Factory(t) {
      return new (t || ColorModeManager)();
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: ColorModeManager,
      factory: ColorModeManager.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorModeManager, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [], null);
})();
const NG_UI_TRANSLATOR = new InjectionToken('ng-ui-translator');
class GenericFormControl extends BaseFormControl {
  constructor() {
    super(...arguments);
    /*************************************** BINDINGS ********************************************************/
    this.changed = new EventEmitter();
    /******************************************************** SIGNALS ********************************************************/
    this.value = computed(() => this._value());
    this._value = signal(null);
  }
  /******************************************************** PROTECTED ********************************************************/
  /** */
  getObject(value) {
    return value;
  }
  /******************************************************** PUBLIC ********************************************************/
  /** */
  updateValue(obj, triggerChanged = true) {
    this._value.set(obj ?? null);
    if (triggerChanged === true) {
      this._onChange && this._onChange(this.value());
      this.changed.emit(this.getObject(this.value()));
    }
  }
  /******************************************************** CONTROL VALUE ACCESSOR ********************************************************/
  /** */
  writeValue(obj) {
    this.updateValue(obj, false);
  }
  static {
    this.ɵfac = /* @__PURE__ */(() => {
      let ɵGenericFormControl_BaseFactory;
      return function GenericFormControl_Factory(t) {
        return (ɵGenericFormControl_BaseFactory || (ɵGenericFormControl_BaseFactory = i0.ɵɵgetInheritedFactory(GenericFormControl)))(t || GenericFormControl);
      };
    })();
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: GenericFormControl,
      outputs: {
        changed: "changed"
      },
      features: [i0.ɵɵInheritDefinitionFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(GenericFormControl, [{
    type: Directive
  }], null, {
    changed: [{
      type: Output
    }]
  });
})();
function inView(element, options = {
  root: null,
  threshold: 0.5
}) {
  return new Observable(subscriber => {
    const observer = new IntersectionObserver(([entry]) => {
      subscriber.next(entry.isIntersecting);
    }, options);
    observer.observe(element);
    return () => observer.disconnect();
  });
}
const regex1 = /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i;
const regex2 = /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i;
function isMobile() {
  const userAgent = navigator.userAgent || navigator.vendor || window['opera'];
  return regex1.test(userAgent) || regex2.test(userAgent.substr(0, 4));
}
;
const withTranslations = type => [{
  provide: NG_UI_TRANSLATOR,
  useClass: type
}];
const provideNgUi = (...configs) => makeEnvironmentProviders(configs);
const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
const charactersLength = characters.length;
function randomId(length = 6) {
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}
function removeDiacritics(value) {
  return value.replace(new RegExp("[àáâãäå]", 'g'), "a").replace(new RegExp("[ÀÁÂÃÄÅ]", 'g'), "A").replace(new RegExp("æ", 'g'), "ae").replace(new RegExp("Æ", 'g'), "AE").replace(new RegExp("ç", 'g'), "c").replace(new RegExp("Ç", 'g'), "C").replace(new RegExp("[èéêë]", 'g'), "e").replace(new RegExp("[ÈÉÊË]", 'g'), "E").replace(new RegExp("[ìíîï]", 'g'), "i").replace(new RegExp("[ÌÍÎÏ]", 'g'), "I").replace(new RegExp("ñ", 'g'), "n").replace(new RegExp("Ñ", 'g'), "N").replace(new RegExp("[òóôõö]", 'g'), "o").replace(new RegExp("[ÒÓÔÕÖ]", 'g'), "O").replace(new RegExp("œ", 'g'), "oe").replace(new RegExp("Œ", 'g'), "OE").replace(new RegExp("[ùúûü]", 'g'), "u").replace(new RegExp("[ÙÚÛÜ]", 'g'), "U").replace(new RegExp("[ýÿ]", 'g'), "y").replace(new RegExp("[ÝŸ]", 'g'), "Y");
}

/**
 * Generated bundle index. Do not edit.
 */

export { BaseFormControl, ColorModeManager, DestroyableComponent, FocusManager, GenericFormControl, NG_UI_TRANSLATOR, afterUi, coerceBoolean, inView, isMobile, provideNgUi, randomId, removeDiacritics, withTranslations };
