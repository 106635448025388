import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { AppSettingsService } from "@sfpd/ng-app-settings";
import { AuthConfig, OAuthService } from 'angular-oauth2-oidc';
import { Observable, ReplaySubject } from "rxjs";
import { AppSettings } from "../models";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  /******************************************************** VARIABLES ********************************************************/

  private _authenticated: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  private _authenticated$: Observable<boolean> = this._authenticated.asObservable();

  private readonly ROUTE_STATE: string = 'notinet_url';

  /******************************************************** ACCESSORS ********************************************************/

  public get authenticated$(): Observable<boolean> {
    return this._authenticated$;
  }

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(
    private _oAuthService: OAuthService,
    private _router: Router,
    private _appSettingService: AppSettingsService<AppSettings>
  ) {
    const config: AuthConfig = {
      issuer: this._appSettingService.settings.aad.issuer,
      redirectUri: window.location.origin,
      strictDiscoveryDocumentValidation: false,
      customQueryParams: { 'domain_hint': 'sfpd.fgov.be' },
      timeoutFactor: 0.5,
      clientId: this._appSettingService.settings.aad.clientId,
      responseType: 'code',
      scope: this._appSettingService.settings.aad.scopes,
      clearHashAfterLogin: true,
      showDebugInformation: false
    };

    this._oAuthService.configure(config);
  }

  /******************************************************** PUBLIC ********************************************************/

  /** */
  public login() {
    return new Observable<boolean>(observer => {
      this._oAuthService
        .loadDiscoveryDocument()
        .then(() => {
          this._oAuthService.setupAutomaticSilentRefresh();

          if (this._oAuthService.hasValidAccessToken() === false) {
            if (sessionStorage.getItem(this.ROUTE_STATE) === null) {
              sessionStorage.setItem(this.ROUTE_STATE, `${location.pathname}${location.search}`);
            }

            this._oAuthService.loadDiscoveryDocumentAndLogin().then(authenticated => {
              if (authenticated === true) {
                observer.next(true);
                observer.complete();

                this._redirect();
              }
            })
          }
          else {
            observer.next(true);
            observer.complete();

            this._redirect();
          }
        })
    });
  }

  /******************************************************** PRIVATE ********************************************************/

  /** */
  private _redirect() {
    const url = sessionStorage.getItem(this.ROUTE_STATE);

    if (url) {
      sessionStorage.removeItem(this.ROUTE_STATE)

      this._router.navigateByUrl(url);
    }

    this._authenticated.next(true);
  }

}
