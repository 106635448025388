import * as i0 from '@angular/core';
import { ElementRef, inject, booleanAttribute, Directive, Input, NgModule } from '@angular/core';
import { fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';

// TODO: Guidelines
class FocusTrapDirective {
  constructor() {
    /******************************************************** SERVICES ********************************************************/
    this._elementRef = inject(ElementRef);
    /******************************************************** BINDINGS ********************************************************/
    this.enabled = false;
    /******************************************************** VARIABLES ********************************************************/
    this._tabbaleElementsSelector = 'a[href], area[href], input:not([disabled]):not([type="hidden"]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable], audio[controls], video[controls], summary, [tabindex^="0"], [tabindex^="1"], [tabindex^="2"], [tabindex^="3"], [tabindex^="4"], [tabindex^="5"], [tabindex^="6"], [tabindex^="7"], [tabindex^="8"], [tabindex^="9"]';
  }
  /******************************************************** ACCESSORS ********************************************************/
  /** */
  get _tabbaleElements() {
    return [...this._elementRef.nativeElement.querySelectorAll(this._tabbaleElementsSelector)].filter(e => e.offsetParent !== null);
  }
  /******************************************************** LIFE CYCLE ********************************************************/
  /** */
  ngOnChanges() {
    if (this.enabled === true) {
      this._subscription = fromEvent(document, 'keydown').pipe(filter(e => e.keyCode === 9)).subscribe(e => this._handleTab(e));
      if (this._tabbaleElements.length === 0) {
        document.body.focus();
      } else {
        this._tabbaleElements[0].focus();
      }
    } else {
      this._subscription?.unsubscribe();
    }
  }
  /** */
  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }
  /******************************************************** PRIVATE ********************************************************/
  /**
   *
   * @param event
   */
  _cancelEvent(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }
  /**
   *
   * @param event
   */
  _handleTab(event) {
    const elements = this._tabbaleElements;
    if (elements.length === 0) {
      this._cancelEvent(event);
      document.body.focus();
    } else {
      if (this._elementRef.nativeElement.contains(document.activeElement) === true) {
        if (event.shiftKey === false && document.activeElement === elements[elements.length - 1]) {
          this._cancelEvent(event);
          elements[0].focus();
        } else if (event.shiftKey === true && document.activeElement === elements[0]) {
          this._cancelEvent(event);
          elements[elements.length - 1].focus();
        }
      } else {
        this._cancelEvent(event);
        elements[0].focus();
      }
    }
  }
  static {
    this.ɵfac = function FocusTrapDirective_Factory(t) {
      return new (t || FocusTrapDirective)();
    };
  }
  static {
    this.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
      type: FocusTrapDirective,
      selectors: [["", "ngUiFocusTrap", ""]],
      inputs: {
        enabled: [2, "ngUiFocusTrap", "enabled", booleanAttribute]
      },
      standalone: true,
      features: [i0.ɵɵInputTransformsFeature, i0.ɵɵNgOnChangesFeature]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FocusTrapDirective, [{
    type: Directive,
    args: [{
      selector: '[ngUiFocusTrap]',
      standalone: true
    }]
  }], null, {
    enabled: [{
      type: Input,
      args: [{
        alias: 'ngUiFocusTrap',
        transform: booleanAttribute
      }]
    }]
  });
})();
const DIRECTIVES = [FocusTrapDirective];
class FocusTrapModule {
  static {
    this.ɵfac = function FocusTrapModule_Factory(t) {
      return new (t || FocusTrapModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: FocusTrapModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({});
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FocusTrapModule, [{
    type: NgModule,
    args: [{
      imports: DIRECTIVES,
      exports: DIRECTIVES
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DIRECTIVES, FocusTrapDirective, FocusTrapModule };
