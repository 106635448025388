<ngs-container [query$]="query$"
               [data$]="data$">
  <ng-template let-notification>
    <div ng-ui-form
         mode="display">
      <div ng-ui-form-control="Audience">
        <div *ngFor="let app of notification.audience">
          {{ app | application }}
        </div>
      </div>
      <div ng-ui-form-control="Data">
        <pre>{{ notification.data | json }}</pre>
      </div>
    </div>
  </ng-template>
</ngs-container>