import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IBaseErrorComponent } from '@sfpd/ng-store';
import { MessageComponent } from '@sfpd/ng-ui/message';

@Component({
  selector: 'noti-eror',
  templateUrl: './error.component.html',
  standalone: true,
  imports: [
    MessageComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ErrorComponent implements IBaseErrorComponent {

  /******************************************************** BINDINGS ********************************************************/

  @Input()
  public error: any;

}
