import { Routes } from '@angular/router';
import { ApplicationsComponent } from '@app/components/applications/applications.component';
import { NotificationsComponent } from '@app/components/notifications/notifications.component';
import { PermissionsComponent } from '@app/components/permissions/permissions.component';
import { AuthGuard } from '@app/guards';

export const ROUTES: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'notifications'
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    component: NotificationsComponent
  },
  {
    path: 'applications',
    canActivate: [AuthGuard],
    component: ApplicationsComponent
  },
  {
    path: 'permissions',
    canActivate: [AuthGuard],
    component: PermissionsComponent
  }
];