import { Type } from "@angular/core";

let _bag: Map<string, Type<any>[]> = new Map();

export function HubService() {
  return function (cls: Type<any>) {
    const proto = cls.prototype;
    
    for (const property of Object.getOwnPropertyNames(proto)) {
      if (property.startsWith('on')) {

        let isFunction = true;

        try { isFunction = typeof proto[property] === 'function'; }
        catch { isFunction = false; }

        if (isFunction === true) {
          _bag.set(property, (_bag.get(property) || []).concat(cls));
        }
      }
    }
  };
}

export function getServicesForMethod(method: string) {
  return _bag.get(method) || [];
}