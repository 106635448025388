import { HttpClient as ngHttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { HttpResponse } from '../models';
import { DateService } from './date.service';

@Injectable({
  providedIn: 'root',
})
export class HttpClient {

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(private _http: ngHttpClient, private _dateService: DateService) { }

  /******************************************************** PUBLIC ********************************************************/

  public delete<T>(route: string): Observable<T> {
    return this._http
      .delete<HttpResponse<T>>(route)
      .pipe(
        tap(response => this._dateService.convertToDate(response.data)),
        map(response => response.data),
        catchError(ex => throwError(ex?.error?.exception || 'error::unknown-error'))
      );
  }

  /**
   * 
   * @param route
   */
  public get<T>(route: string): Observable<T> {
    return this._http
      .get<HttpResponse<T>>(route)
      .pipe(
        tap(response => this._dateService.convertToDate(response.data)),
        map(response => response.data),
        catchError(ex => throwError(ex?.error?.exception || 'error::unknown-error'))
      );
  }

  /**
   * 
   * @param route
   * @param payload
   */
  public patch<T>(route: string, payload: unknown = null): Observable<T> {
    return this._http
      .patch<HttpResponse<T>>(route, payload)
      .pipe(
        tap(response => this._dateService.convertToDate(response.data)),
        map(response => response.data),
        catchError(ex => throwError(ex?.error?.exception || 'error::unknown-error'))
      );
  }

  /**
   * 
   * @param route
   * @param payload
   */
  public post<T>(route: string, payload: unknown = null): Observable<T> {
    return this._http
      .post<HttpResponse<T>>(route, payload)
      .pipe(
        tap(response => this._dateService.convertToDate(response.data)),
        map(response => response.data),
        catchError(ex => throwError(ex?.error?.exception || 'error::unknown-error'))
      );
  }

  /**
   * 
   * @param route
   * @param payload
   */
  public put<T>(route: string, payload: unknown = {}): Observable<T> {
    return this._http
      .put<HttpResponse<T>>(route, payload)
      .pipe(
        tap(response => this._dateService.convertToDate(response.data)),
        map(response => response.data),
        catchError(ex => throwError(ex?.error?.exception || 'error::unknown-error'))
      );
  }
}
