import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { User } from '@app/models';
import { UserService } from '@app/services';
import { AppState } from '@app/store';
import { NgStoreModule, StoreComponent } from '@sfpd/ng-store';
import { IconComponent } from '@sfpd/ng-ui/icon';
import { ColumnFilterExtractor, ColumnValueComparer, TableBodyEmptyRowDirective, TableBodyRowDirective, TableComponent, TableHeaderComponent, TableHeaderRowDirective } from '@sfpd/ng-ui/table';
import { ToasterService } from '@sfpd/ng-ui/toaster';
import { TooltipDirective } from '@sfpd/ng-ui/tooltip';
import { Observable, map, of, shareReplay } from 'rxjs';
import { UserPipe } from '../../pipes';

@Component({
  selector: 'noti-permissions',
  templateUrl: './permissions.component.html',
  styleUrls: ['./permissions.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    IconComponent,
    NgStoreModule,
    TableComponent,
    TableHeaderComponent,
    TableHeaderRowDirective,
    TableBodyEmptyRowDirective,
    TableBodyRowDirective,
    UserPipe,
    TooltipDirective
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserPipe]
})
export class PermissionsComponent extends StoreComponent<AppState, User[]> implements OnInit {

  /******************************************************** OBSERVABLES ********************************************************/

  public processing$: Observable<boolean> = this._store.select(s => s.users).pipe(map(e => e.busy === true), shareReplay(1));

  /******************************************************** VARIABLES ********************************************************/

  public identityValueComparer: ColumnValueComparer<User> = (u1, u2, _, direction) => this._userPipe.transform(u1.id).localeCompare(this._userPipe.transform(u2.id)) * (direction === 'ascending' ? 1 : -1);
  public identityFilterExtractor: ColumnFilterExtractor<User> = user => [user.firstName, user.lastName, this._userPipe.transform(user.id), user.email]

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(
    private _userPipe: UserPipe,
    private _userService: UserService,
    private _toastService: ToasterService
  ) {
    super();
  }

  public ngOnInit(): void {
    this.query$ = of(null);
    this.data$ = this._store.selectValues(s => s.users);
  }

  /******************************************************** PUBLIC ********************************************************/

  /**
   * 
   * @param id
   */
  public switch(id: number) {
    this._userService
      .switchPermission(id)
      .subscribe({
        error: err => this._toastService.error(err)
      })
  }
}
