import { Injectable } from '@angular/core';
import { HubService } from '@app/decorators';
import { AppState } from '@app/store';
import { NgStore } from '@sfpd/ng-store';
import { Observable } from 'rxjs';
import { Notification, NotificationUpsertData } from '../models';

@Injectable({
  providedIn: 'root',
})
@HubService()
export class NotificationService {

  /******************************************************** VARIABLES ********************************************************/

  private readonly ROUTE: string = '/api/private/notifications';

  /******************************************************** LIFE CYCLE ********************************************************/

  constructor(private _store: NgStore<AppState>) { }

  /******************************************************** PUBLIC ********************************************************/

  /** */
  public deleteNotification(id: number): Observable<Notification> {
    return this._store.deleteEntityByKey(`${this.ROUTE}/${id}`, s => s.notifications, id);
  }

  /**
   * 
   * @param from
   * @param to
   */
  public loadNotifications(from: Date, to: Date): Observable<Notification[]> {
    return this._store.loadEntitiesOnce(`${this.ROUTE}?from=${from.toJSON()}&to=${to.toJSON()}`, s => s.notifications, false, true);
  }

  /** */
  public loadNotification(id: number): Observable<Notification> {
    return this._store.loadEntityByKey(`${this.ROUTE}/${id}`, s => s.notifications, id);
  }

  /** */
  public upsert(data: NotificationUpsertData): Observable<Notification> {
    return this._store.putEntityByKey(this.ROUTE, s => s.notifications, null, data);
  }

  /******************************************************** PUBLIC ********************************************************/

  /** */
  public onNotificationDeleted(notification: Notification) {
    this._store.removeEntitiesByKeys(s => s.notifications, notification.id);
  }

  /**
   * 
   * @param notifications
   */
  public onNotificationCreated(notifications: Notification | Notification[]) {
    if (Array.isArray(notifications)) {
      this._store.upsertValues(s => s.notifications, notifications, { loaded: false });
    }
    else {
      this._store.upsertValue(s => s.notifications, notifications, { loaded: false });
    }
  }

  /**
   * 
   * @param notifications
   */
  public onNotificationRead(notifications: Notification | Notification[]) {
    notifications = Array.isArray(notifications) ? notifications : [notifications];
    console.log(notifications)
    for (const notification of notifications) {
      this._store.updateValueByKey(s => s.notifications, notification.id, n => n.readAt = notification.readAt);
    }
  }

}
